import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import AppContext from 'context/Context';
import { Formik, Field, Form as FormikForm } from 'formik';
import { FormTextField, FormTextAreaField, FormikSelect4, FormikDate, CustomDateInput } from 'helpers/bootstrap-formlk';
import LoanAccountService from 'services/LoanAccountService';
import UserService from 'services/userService';
import MoneyAccountService from 'services/MoneyAccountService';
import DatePicker from "react-datepicker";
import moment from 'moment';
import * as Yup from 'yup';

//https://codesandbox.io/s/react-bootstrap-formik-pb831?file=/src/form-field.js


const AddExpense = ({
    data,
    isDialogOpened,
    onCloseDialog
}) => {
    const {
        config: { isDark }
    } = useContext(AppContext);

    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState(null);
    const [expenseCategories, setExpenseCategories] = useState([]);
    const [moneyAccounts, setMoneyAccounts] = useState([]);

    function defaultState() {
        setError('');
    }


    useEffect(() => {
        defaultState();

        if (data) {
            //console.log(data);
            setFormData(data);
        }

    }, [data]);


    function handleClose(isSubmitted) {
        onCloseDialog(isSubmitted);
    }



    function checkDefaultValue() {

    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const submit = async (values) => {

        try {
            setIsLoading(true);
            console.log("add loan", values);

            LoanAccountService.createLoanAccount(values.accountName, values.details, values.loanAmount, values.interestRate, values.loanTerm, values.installmentAmount, values.startDate, values.endDate, 1).then(
                response => {
                    handleClose(true);
                },
                error => {
                    setError(error);
                }
            )
        } catch (err) {
            setError(err);
        } finally {
            setIsLoading(false);
        }
    };
    const [date, setDate] = useState(null);

    var minDate = moment().year(2023).month(1).date(1);
    var maxDate = moment().toDate();

    const AddExpenseSchema = Yup.object().shape({
        accountName: Yup.string()
            .required("Plese enter account name"),
        loanAmount: Yup.number()
            .min(1, 'Enter valid amount')
            .required('Amount is required'),
        interestRate: Yup.number()
            .required('Interest rate is required'),
        loanTerm: Yup.number()
            .min(1, 'Enter valid loan term')
            .required('Loan term is required'),
        installmentAmount: Yup.number()
            .min(1, 'Enter valid EMI aamount')
            .required('EMI is required'),
        startDate: Yup.date()
            .required('Start date is required'),
        endDate: Yup.date()
            .required('End date is required'),
    });

    return (
        <Modal
            show={isDialogOpened}
            onHide={e => handleClose(false)}
            contentClassName="border"
            backdrop="static"
            centered
        >
            <Formik
                initialValues={formData}
                enableReinitialize={true}
                validationSchema={AddExpenseSchema}
                onSubmit={submit}>

                {({ errors, touched, values, setFieldValue }) => (

                    <FormikForm >
                        <Modal.Header
                            closeButton
                            closeVariant={isDark ? 'white' : undefined}
                            className="bg-light px-x1 border-bottom-0"
                        >
                            <Modal.Title as="h5"> New Loan</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="p-x1">

                            <Row>
                                <Col xs={12}>
                                    <Form.Group className="mb-3" controlId="formGroupName" >
                                        <Form.Label className="fs-0">Account Name</Form.Label>
                                        <FormTextField name="accountName" type="text" />
                                        <Form.Text className="text-danger">
                                            {errors.accountName && touched.accountName ? (
                                                <div> {errors.accountName} </div>
                                            ) : null}
                                        </Form.Text>
                                    </Form.Group>
                                </Col>
                                <Col xs={12}>
                                    <Form.Group className="mb-3" controlId="formDescription" col={6}>
                                        <Form.Label className="fs-0">Details</Form.Label>
                                        <FormTextAreaField name="details" rows={2} />
                                        <Form.Text className="text-danger">
                                            {errors.details && touched.details ? (
                                                <div> {errors.details} </div>
                                            ) : null}
                                        </Form.Text>
                                    </Form.Group>
                                </Col>
                                <Col xs={6}>
                                    <Form.Group className="mb-3" controlId="formCategoryName">
                                        <Form.Label className="fs-0">Loan Amount</Form.Label>
                                        <FormTextField name="loanAmount" type="text" />
                                        <Form.Text className="text-danger">
                                            {errors.loanAmount && touched.loanAmount ? (
                                                <div> {errors.loanAmount} </div>
                                            ) : null}
                                        </Form.Text>

                                    </Form.Group>
                                </Col>
                                <Col xs={6}>
                                    <Form.Group className="mb-3" controlId="formCategoryName">
                                        <Form.Label className="fs-0">Interest Rate</Form.Label>
                                        <FormTextField name="interestRate" type="text" />
                                        <Form.Text className="text-danger">
                                            {errors.interestRate && touched.interestRate ? (
                                                <div> {errors.interestRate} </div>
                                            ) : null}
                                        </Form.Text>
                                    </Form.Group>
                                </Col>

                                <Col xs={6}>
                                    <Form.Group className="mb-3" controlId="formCategoryName">
                                        <Form.Label className="fs-0">Loan Term (In Months)</Form.Label>
                                        <FormTextField name="loanTerm" type="text" />
                                        <Form.Text className="text-danger">
                                            {errors.loanTerm && touched.loanTerm ? (
                                                <div> {errors.loanTerm} </div>
                                            ) : null}
                                        </Form.Text>
                                    </Form.Group>
                                </Col>
                                <Col xs={6}>
                                    <Form.Group className="mb-3" controlId="formCategoryName">
                                        <Form.Label className="fs-0">EMI</Form.Label>
                                        <FormTextField name="installmentAmount" type="text" />
                                        <Form.Text className="text-danger">
                                            {errors.installmentAmount && touched.installmentAmount ? (
                                                <div> {errors.installmentAmount} </div>
                                            ) : null}
                                        </Form.Text>

                                    </Form.Group>
                                </Col>
                                <Col xs={6}>
                                    <Form.Group className="mb-3" controlId="formExpenseDate">
                                        <Form.Label className="fs-0">Start Date</Form.Label>
                                        <DatePicker
                                            selected={values.startDate}
                                            onChange={date => {
                                                setFieldValue('startDate', date);
                                            }}
                                            className="form-control"
                                            placeholderText="DD-MM-YYYY"
                                            dateFormat="dd-MM-yyyy"
                                        />
                                        <Form.Text className="text-danger">

                                            {errors.startDate && touched.startDate ? (
                                                <div> {errors.startDate} </div>
                                            ) : null}
                                        </Form.Text>
                                    </Form.Group>
                                </Col>
                                <Col xs={6}>
                                    <Form.Group className="mb-3" controlId="formExpenseDate">
                                        <Form.Label className="fs-0">End Date</Form.Label>
                                        <DatePicker
                                            selected={values.endDate}
                                            onChange={date => {
                                                setFieldValue('endDate', date);
                                            }}
                                            className="form-control"
                                            placeholderText="DD-MM-YYYY"
                                            dateFormat="dd-MM-yyyy"
                                        />
                                        <Form.Text className="text-danger">
                                            {errors.endDate && touched.endDate ? (
                                                <div> {errors.endDate} </div>
                                            ) : null}
                                        </Form.Text>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer className="bg-light px-x1 border-top-0">

                            <Button
                                variant="default"
                                type="button"
                                onClick={e => handleClose(false)}
                                className="px-4 mx-0"
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                type="submit"
                                //onClick={handleClose}
                                className="px-4 mx-0 ms-2"
                            >
                                Save
                            </Button>
                        </Modal.Footer>
                    </FormikForm>
                )}
            </Formik>
        </Modal >
    );
};

AddExpense.propTypes = {
    data: PropTypes.object.isRequired,
    isDialogOpened: PropTypes.bool.isRequired,
    onCloseDialog: PropTypes.func.isRequired
};

export default AddExpense;
