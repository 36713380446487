import React from 'react';
import Hero from './Hero';
import NavbarStandard from './NavbarStandard';
//import Partners from './Partners';
//import Processes from './Processes';
//import Services from './Services';
//import Testimonial from './Testimonial';
//import Cta from './Cta';
//import FooterStandard from './FooterStandard';

const Home = () => {
  return (
    <>
      <NavbarStandard />

      {/* <h1 className="display-6 mb-3">Boost Productivity with WebApp</h1> */}

      <Hero />
      {/* <Partners /> */}
      {/* <Processes /> */}
      {/* <Services /> */}
      {/* <Testimonial /> */}
      {/* <Cta /> */}
      {/* <FooterStandard /> */}
    </>
  );
};

export default Home;
