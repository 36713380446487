import { DataUtil } from '@syncfusion/ej2-data';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import * as React from 'react';
import { DataManager, WebApiAdaptor, Query } from '@syncfusion/ej2-data';
import { apiURL } from 'services/api';

export class DialogFormTemplate extends React.Component {
    shipCountryDistinctData = [];
    //const [ExpenseData, setExpenseData] = useState();

    constructor(props) {
        super(props);
        this.state = Object.assign({}, props);

        console.log('DialogFormTemplate', this.state);

        if (this.state.isAdd) {
            this.state.expenseDate = new Date();
        }

        DataUtil.serverTimezoneOffset = 0;
    }
    onChange(args) {
        console.log('onchange ' + args.target.name, args);

        var name = args.target.name;
        var value = args.value;

        // if (name === 'expenseDate') {
        //     value = parseDate(value);
        // }

        this.setState({ [name]: value });
    }

    categoryFields = { text: 'categoryName', value: 'id' };
    accountFields = { text: 'accountName', value: 'id' };

    categoryDataSource = new DataManager({
        adaptor: new WebApiAdaptor(),
        crossDomain: true,
        url: apiURL + "/ExpenseCategory/getall",
        offline: true
    })

    accountDataSource = new DataManager({
        adaptor: new WebApiAdaptor(),
        crossDomain: true,
        url: apiURL + "/MoneyAccount/getall",
        offline: true
    })

    render() {

        this.onChange = this.onChange.bind(this);

        const data = this.state;

        return (<div>


            <div className="form-row">
                <div className="form-group col-md-12">
                    <NumericTextBoxComponent id="amount" format='N2' value={data.amount} placeholder="Amount" floatLabelType='Always' showSpinButton={false} />
                </div>
                <div className="form-group col-md-12">
                    {/* <DatePickerComponent id="expenseDate" value={data.expenseDate} fields={this.expenseDate} placeholder="Expense Date" format='dd-MMM-yyyy' popupHeight='300px' floatLabelType='Always' /> */}
                    <DatePickerComponent id="expenseDate" name="expenseDate" value={data.expenseDate} placeholder="Expense Date" format='dd-MMM-yyyy' floatLabelType='Always' />
                </div>
                <div className="form-group col-md-12">
                    <DropDownListComponent id="expenseCategoryId" value={data.expenseCategoryId} dataSource={this.categoryDataSource} fields={this.categoryFields} allowFiltering={true} filterType='Contains' placeholder="Category" popupHeight='300px' floatLabelType='Always' />
                </div>
                <div className="form-group col-md-12">
                    <DropDownListComponent id="moneyAccountId" value={data.moneyAccountId} dataSource={this.accountDataSource} fields={this.accountFields} allowFiltering={true} filterType='Contains' placeholder="Account" popupHeight='300px' floatLabelType='Always' />
                </div>
            </div>


            <div className="form-row">
                <div className="form-group col-md-12">
                    <div className="e-float-input e-control-wrapper">
                        <textarea id="description" name="description" value={data.description} onChange={this.onChange} />
                        <span className="e-float-line" />
                        <label className="e-float-text e-label-top">Description</label>
                    </div>
                </div>
            </div>
        </div>);
    }
}