import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart, PieChart } from 'echarts/charts';
import {
    GridComponent,
    LegendComponent,
    TitleComponent,
    TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor, rgbaColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { Card, Col, Row } from 'react-bootstrap';

echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LineChart,
    CanvasRenderer,
    LegendComponent,
    PieChart
]);


const CategoryPieChart = ({ data2 }) => {

    const chartRef = useRef(null)
    const data = [
        {
            value: 800,
            name: 'Starter',
            itemStyle: {
                color: rgbaColor(getColor('primary'), 0.5)
            }
        },
        {
            value: 1048,
            name: 'Starter Pro',
            itemStyle: {
                color: getColor('danger')
            }
        },
        {
            value: 735,
            name: 'Basic',
            itemStyle: {
                color: getColor('primary')
            }
        },
        {
            value: 580,
            name: 'Optimal',
            itemStyle: {
                color: getColor('secondary')
            }
        },
        {
            value: 484,
            name: 'Business',
            itemStyle: {
                color: getColor('warning')
            }
        },
        {
            value: 600,
            name: 'Classic addition',
            itemStyle: {
                color: rgbaColor(getColor('warning'), 0.8)
            }
        },
        {
            value: 300,
            name: 'Premium',
            itemStyle: {
                color: getColor('success')
            }
        },

    ];

    const getOption = () => ({
        title: [
            {
                text: 'Pie Label Align Chart',
                left: 'center',
                textStyle: {
                    color: getColor('gray-600')
                }
            },
            {
                subtext: 'alignTo: "labelLine"',
                left: '50%',
                top: '85%',
                textAlign: 'center',
                subtextStyle: {
                    color: getColor('gray-700')
                }
            }
        ],

        tooltip: {
            trigger: 'item',
            padding: [7, 10],
            backgroundColor: getColor('gray-100'),
            borderColor: getColor('gray-300'),
            textStyle: { color: getColor('dark') },
            borderWidth: 1,
            transitionDuration: 0,
            axisPointer: {
                type: 'none'
            }
        },

        series: [
            {
                type: 'pie',
                radius: window.innerWidth < 530 ? '45%' : '60%',
                center: ['50%', '50%'],
                data: data,
                label: {
                    position: 'outer',
                    alignTo: 'edge',
                    bleedMargin: 5,
                    color: getColor('gray-700')
                },
                left: '5%',
                right: '5%',
                top: 0,
                bottom: 0
            }
        ]
    });

    //------- Responsive on window resize -------

    const updateDimensions = () => {
        if (window.innerWidth < 530) {
            chartRef.current.getEchartsInstance().setOption({
                series: [{ radius: '45%' }]
            });
        }
        else
            chartRef.current.getEchartsInstance().setOption({
                series: [{ radius: '60%' }]
            });
    }

    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    return (
        <Card className="h-100">
            <FalconCardHeader
                title="Top 10 Categories"
                titleTag="h6"
                className="py-3"
                light
            />

            <Card.Body as={Row}>
                <Col md={12}>
                    <ReactEChartsCore
                        echarts={echarts}
                        option={getOption()}
                        ref={chartRef}
                        style={{ height: '20rem' }}
                    />
                </Col>
            </Card.Body>
        </Card>
    );
}

export default CategoryPieChart;