
export const transactionData = [
    {
        transactionDate: '21/08/2023',
        category: 'Rent',
        notes: 'For the month July, 2023',
        amount: '13,300',
    },
    {
        transactionDate: '16/08/2023',
        category: 'Groceries',
        notes: 'Limbu & Kothimbir',
        amount: '20',
    },
    {
        transactionDate: '16/08/2023',
        category: 'Groceries',
        notes: 'Chicken & Garlic Paste',
        amount: '210',
    },
    {
        transactionDate: '06/08/2023',
        category: 'Maintanance & Oil Change',
        notes: 'Front & Rear Break Pad, servicing',
        amount: '980',
    },
]