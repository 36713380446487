import React, { useState, useEffect } from 'react';
import { Card, Dropdown, Col, Form, Row, Button } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { categoriesData } from 'data/expensesCategoriesData';
import CardDropdown from 'components/common/CardDropdown';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
//import CategoriesTableHeader from './CategoriesTableHeader';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import UserService from 'services/userService';
import AddExpenseCategory from './AddExpenseCategory';
import EditExpenseCategory from './EditExpenseCategory'
import DeleteExpenseCategory from './DeleteExpenseCategory';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';



const Categories = () => {

    const [groups, setGroups] = useState([]);
    const [error, setError] = useState();

    const [addDialogData, setAddDialogData] = useState({ id: 0, categoryName: '', description: '', expenseGroupId: 0 });
    const [isAddGroupDialogOpen, setIsOpenAddGroupDialog] = useState(false);

    const [editDialogData, setEditDialogData] = useState({ id: 0, categoryName: '', description: '', expenseGroupId: 0 });
    const [isEditGroupDialogOpen, setIsOpenEditGroupDialog] = useState(false);

    const [deleteDialogData, setDeleteDialogData] = useState({ id: 0, categoryName: '', command: '' });
    const [isDeleteCategoryDialogOpen, setIsOpenDeleteCategoryDialog] = useState(false);

    useEffect(() => {

        loadData();

    }, []);

    function loadData() {
        try {

            UserService.getExpenseCategories().then(
                response => {

                    if (response.data) {
                        setGroups(response.data);
                    }
                },
                error => {
                    setError(error);
                }
            )
        } catch (err) {
            setError(err);
        } finally {
            //setFormLoaded(true);
        }
    }

    const openAddCategoryDialog = () => {

        setAddDialogData({
            id: 0,
            categoryName: '',
            description: '',
            expenseGroupId: 0,
        })

        setIsOpenAddGroupDialog(true);

    }

    function handleCloseAddCategoryDialog(isSubmitted) {
        setIsOpenAddGroupDialog(false);

        if (isSubmitted) {
            loadData();
        }
    }

    const openEditCategoryDialog = (id) => {

        try {

            UserService.getExpenseCategoryById(id).then(
                response => {
                    //console.log("expense group received " + id);
                    console.log("openEditCategoryDialog", response.data);
                    setEditDialogData(response.data);

                    // if (response.data) {
                    //     setGroups(response.data);
                    // }
                },
                error => {

                }
            )
        } catch (err) {
        } finally {

        }

        setIsOpenEditGroupDialog(true);

    }

    function handleCloseEditCategoryDialog(isSubmitted) {
        setIsOpenEditGroupDialog(false);

        if (isSubmitted) {
            loadData();
        }
    }

    const openDeleteCategoryDialog = (id, categoryName) => {


        setDeleteDialogData({
            id: id,
            categoryName: categoryName,
            command: ''
        })

        setIsOpenDeleteCategoryDialog(true);

    }

    function handleCloseDeleteDialog(isSubmitted) {
        setIsOpenDeleteCategoryDialog(false);

        if (isSubmitted) {
            loadData();
        }
    }


    const [customers] = useState(categoriesData);

    const columns = [
        {
            accessor: 'categoryName',
            Header: 'Name',
            Cell: rowData => {
                const { categoryName } = rowData.row.original;
                return <h5 className="mb-0 fs--1">{categoryName}</h5>
            }
        },
        {
            accessor: 'expenseGroupName',
            Header: 'Group',
            headerProps: { style: { minWidth: '200px' }, className: 'ps-5' },
            cellProps: { className: 'ps-5' }
        },
        {
            accessor: 'description',
            Header: 'Description'
        },
        {
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            cellProps: {
                className: 'text-end'
            },
            Cell: (rowData) => {
                const { id, categoryName } = rowData.row.original;

                return (
                    <CardDropdown iconClassName="fs--1">
                        <div className="py-2">
                            <Dropdown.Item onClick={() => {
                                openEditCategoryDialog(id);
                            }}>Edit</Dropdown.Item>

                            <Dropdown.Item onClick={() => {
                                openDeleteCategoryDialog(id, categoryName);
                            }}>Delete</Dropdown.Item>
                        </div>
                    </CardDropdown>
                );
            }
        }
    ];


    const CategoriesTableHeader = ({
        selectedRowIds,
        globalFilter,
        setGlobalFilter
    }) => {
        return (
            <Row className="flex-between-center">
                {/* <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                    <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Categories</h5>
                </Col> */}
                <Col xs={4} >
                    <AdvanceTableSearchBox
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                        placeholder="Search"
                    />
                </Col>
                <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                    {Object.keys(selectedRowIds).length > 0 ? (
                        <div className="d-flex">
                            <Form.Select size="sm" aria-label="Bulk actions">
                                <option>Bulk Actions</option>
                                <option value="refund">Refund</option>
                                <option value="delete">Delete</option>
                                <option value="archive">Archive</option>
                            </Form.Select>
                            <Button
                                type="button"
                                variant="falcon-default"
                                size="sm"
                                className="ms-2"
                            >
                                Apply
                            </Button>
                        </div>
                    ) : (
                        <div id="orders-actions">
                            <IconButton
                                variant="falcon-primary"
                                size="sm"
                                icon="plus"
                                transform="shrink-3"
                                onClick={() => {
                                    openAddCategoryDialog();
                                }}
                            >
                                <span className="d-none d-sm-inline-block ms-1">New Category</span>

                            </IconButton>
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon="filter"
                                transform="shrink-3"
                                className="mx-2"
                            >
                                <span className="d-none d-sm-inline-block ms-1">Filter</span>
                            </IconButton>
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon="external-link-alt"
                                transform="shrink-3"
                            >
                                <span className="d-none d-sm-inline-block ms-1">Export</span>
                            </IconButton>
                        </div>
                    )}
                </Col>
            </Row>
        );
    };

    return (
        <>

            {
                error && <>
                    <div className="d-block bg-danger p-2 text-white mt-3">{error}</div>
                    <br />
                </>
            }

            <Card className="mb-3">
                <Card.Body>
                    <Row className="justify-content-between align-items-center">
                        <Col md>
                            <h5 className="mb-2 mb-md-0">Categories</h5>
                        </Col>
                        <Col xs="auto">
                        </Col>

                    </Row>
                </Card.Body>
            </Card>

            <AdvanceTableWrapper
                columns={columns}
                data={groups}
                selection
                sortable
                pagination
                perPage={10}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <CategoriesTableHeader table />
                    </Card.Header>
                    <Card.Body className="p-0">

                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination table />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>

            <AddExpenseCategory
                data={addDialogData}
                isDialogOpened={isAddGroupDialogOpen}
                onCloseDialog={handleCloseAddCategoryDialog}
            />

            <EditExpenseCategory
                data={editDialogData}
                isDialogOpened={isEditGroupDialogOpen}
                onCloseDialog={handleCloseEditCategoryDialog}
            />

            <DeleteExpenseCategory
                data={deleteDialogData}
                isDialogOpened={isDeleteCategoryDialogOpen}
                onCloseDialog={handleCloseDeleteDialog}
            />
        </>

    );
};

export default Categories;
