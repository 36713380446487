import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, Navigate } from 'react-router-dom';
import AppContext from 'context/Context';
import classNames from 'classnames';
import NavbarTop from 'navigation/NavbarTop';
import NavbarVertical from 'navigation/NavbarVertical';
import TokenService from 'services/tokenService';
import { useUser } from 'hooks/useUser';
import { AuthContext } from "context/Auth";

//import Footer from 'components/footer/Footer';
//import ProductProvider from 'components/app/e-commerce/ProductProvider';
//import CourseProvider from 'components/app/e-learning/CourseProvider';


const MainLayout = ({ isAuthenticated }) => {

    //console.log(`Main-Layout Authenticated ${isAuthenticated}`);
    console.log("Welcome!");

    const { currentUser } = useContext(AuthContext);


    const { hash, pathname } = useLocation();
    const isKanban = pathname.includes('kanban');
    // const isChat = pathname.includes('chat');


    const {
        config: { isFluid, navbarPosition }
    } = useContext(AppContext);

    //console.log("main layout isFluid", isFluid);

    useEffect(() => {
        setTimeout(() => {
            if (hash) {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView({ block: 'start', behavior: 'smooth' });
                }
            }
        }, 0);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    //const { authenticated } = useUser();

    // const [authenticated2, setAuthenticated2] = useState(false);

    // useEffect(() => {
    //     var token = TokenService.getLocalAccessToken();
    //     console.log("useEffect: " + token);
    //     if (token) {
    //         setAuthenticated2(true);
    //         console.log("useEffect: Authenticated");
    //     }

    // }, []);

    return (
        currentUser ? (
            <div className={isFluid ? 'container-fluid' : 'container'}>
                {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
                    <NavbarVertical />
                )}
                <div className={classNames('content', { 'pb-0': isKanban })}>
                    <NavbarTop />
                    <Outlet />
                </div>

            </div>
        ) : (
            <Navigate to="/login" />
        )

    );

    //return currentUser ? <Outlet /> : <Navigate to="/login" />;

    // return (

    //     <div className={isFluid ? 'container-fluid' : 'container'}>
    //         {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
    //             <NavbarVertical />
    //         )}
    //         <div className={classNames('content', { 'pb-0': isKanban })}>
    //             <NavbarTop />
    //             <Outlet />
    //         </div>

    //     </div>

    // );
};

export default MainLayout;
