import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Form, Alert } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import AppContext from 'context/Context';
import DatePicker from 'react-datepicker';
import { Formik, Field, Form as FormikForm } from 'formik';
import { FormTextField, FormTextAreaField } from 'helpers/bootstrap-formlk';
import ExpenseService from 'services/ExpenseService';
import { parseError } from 'helpers/api-utils';
import MoneyPlanService from 'services/MoneyPlanService';

//https://codesandbox.io/s/react-bootstrap-formik-pb831?file=/src/form-field.js


const DeleteExpense = ({
    data,
    isDialogOpened,
    onCloseDialog
}) => {
    const {
        config: { isDark }
    } = useContext(AppContext);

    const INITIAL_VALUES = { id: 0, amount: '', command: '' };

    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState(INITIAL_VALUES);

    function defaultState() {
        setError('');
    }

    useEffect(() => {
        defaultState();

        if (data) {
            setFormData(data);
        }

    }, [data]);


    function handleClose(isSubmitted) {
        onCloseDialog(isSubmitted);
    }

    function handleAPIError(error) {
        if (error.response) {
            return setError(error.response.data);
        }
        return setError(error.message);
    }

    return (
        <Modal
            show={isDialogOpened}
            onHide={e => handleClose(false)}
            contentClassName="border"
            backdrop="static"
            centered
        >
            <Formik
                initialValues={formData}
                enableReinitialize={true}
                onSubmit={async (values, actions) => {

                    try {

                        if (values.command.toLowerCase() === 'delete') {

                            setIsLoading(true);

                            MoneyPlanService.deleteMoneyPlan(values.id).then(
                                response => {
                                    handleClose(true);
                                },
                                error => {
                                    //var message = parseError(error);
                                    setError(error);
                                }
                            )
                        } else {
                            setError("input required!")
                        }
                    } catch (err) {
                        setError(err);
                    } finally {
                        //setIsLoading(false);
                    }
                }}>

                <FormikForm >
                    <Modal.Header
                        closeButton
                        closeVariant={isDark ? 'white' : undefined}
                        className="bg-light px-x1 border-bottom-0"
                    >
                        <Modal.Title as="h5"> Delete Expense</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-x1">
                        {
                            error && <>
                                <div className="d-block bg-danger p-2 text-white mt-3">{error}</div>
                                <br />
                            </>
                        }
                        <Form.Group className="mb-3" controlId="formGroupName">
                            <Form.Label className="fs-0">To confirm deletion '{data.amount}' expense, enter 'Delete' in the below field.</Form.Label>
                            <FormTextField name="command" type="text" />
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer className="bg-light px-x1 border-top-0">

                        <Button
                            variant="default"
                            type="button"
                            onClick={e => handleClose(false)}
                            className="px-4 mx-0"
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            type="submit"
                            //onClick={handleClose}
                            className="px-4 mx-0 ms-2"
                        >
                            Delete
                        </Button>
                    </Modal.Footer>
                </FormikForm>
            </Formik>
        </Modal>
    );
};

DeleteExpense.propTypes = {
    deleteDialog: PropTypes.object,
};

export default DeleteExpense;
