import React, { forwardRef } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { Formik, Field, useField } from 'formik';
//import Select from 'react-select'
import Select, { Option, ReactSelectProps } from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


export const FormControl = ({ field, form, ...props }) => {
    return <Form.Control {...field} {...props} />;
};

export const FormTextArea = ({ field, form, ...props }) => {
    return <Form.Control as="textarea" rows={3}  {...field} {...props} />;
};



export const FormTextField = ({
    name,
    type,
}) => {
    return (
        // <Field
        //     name={name}
        //     render={({ field, form }) => {
        //         return (
        //             <Form.Control
        //                 {...field}
        //                 type={type}
        //             />
        //         );
        //     }}
        // />
        <Field
            name={name}>
            {
                ({ field, form }) => {
                    return (
                        <Form.Control
                            {...field}
                            type={type}
                        />
                    );
                }
            }
        </Field>

    );
};


export const FormTextAreaField = ({
    name,
    type,
    rows,
}) => {
    return (
        <Field
            name={name}>
            {
                ({ field, form }) => {
                    return (
                        <Form.Control
                            as="textarea"
                            {...field}
                            type={type}
                        />
                    );
                }
            }
        </Field>

    );
};

export const FormSelectField2 = ({
    name,
    type,
    children,
}) => {
    return (
        <Field
            name={name}>
            {
                ({ field, form }) => {
                    return (
                        <Form.Control
                            {...field}
                            type={type}
                            as="select"
                        >
                            {children}
                        </Form.Control>
                    );
                }
            }
        </Field>

    );
};

export const FormSelectField = ({
    ...props
}) => {

    const [field, meta, { setValue, setTouched }] = useField(props);
    const options = props.children.map((option) => ({
        value: option.props.value,
        label: option.props.children,
    }));

    const onChange = ({ value }) => {
        setValue(value);
    };

    console.log("field value ", field.value);

    return (
        // <Field>
        //     {
        //         ({ field, form }) => {
        //             console.log("field value ", field.value);
        //             return (
        //                 <Select
        //                     defaultValue={options.find((option) => option.value === field.value)}
        //                     options={options}
        //                     classNamePrefix="react-select"
        //                     onChange={onChange}
        //                     onBlur={setTouched}
        //                 />
        //             );
        //         }
        //     }
        // </Field>

        <Select
            defaultValue={options.find((option) => option.value === field.value)}
            options={options}
            classNamePrefix="react-select"
            onChange={onChange}
            onBlur={setTouched}
        />
    );




};


export const FormikSelect = ({
    selectOptions,
    formikFieldName,
    placeholder,
}) => {
    // eslint-disable-next-line no-unused-vars
    const [field, _, helpers] = useField(formikFieldName);
    const { setValue } = helpers;

    return (
        <Select
            defaultValue={selectOptions.find(
                (option) => option.value === field.value
            )}
            options={selectOptions}
            placeholder={placeholder}
            onBlur={field.onBlur}
            onChange={(option) => setValue(option.value)}
        />
    );
};


export const FormikSelect3 = ({
    onChange, options, value,
}) => {
    // eslint-disable-next-line no-unused-vars
    const defaultValue = (options, value) => {
        console.log("option", options);
        console.log("value", value);

        return options ? options.find(option => option.value === value) : "";
    };

    return (
        <Select
            classNamePrefix="react-select"
            value={defaultValue(options, value)}
            onChange={value => {
                onChange(value)
            }} options={options} />
    );
};






export const DropDown = ({
    label,
    ...props
}) => {
    // This isn't an input, so instead of using the values in 'field' directly,
    // we'll use 'meta' and 'helpers'.

    const [field, meta, helpers] = useField(props);

    const { options } = props;
    const { touched, error, value } = meta;
    const { setValue } = helpers;

    return (
        <div>
            {/*<SelectField
                {...field}
                {...props}
                value={value || ""}
                onChange={e => setValue(e.target.value)}
              >
                <option value="" disabled></option>
                <option value="grapefruit">Grapefruit</option>
                <option value="lime">Lime</option>
                <option value="coconut">Coconut</option>
                <option value="mango">Mango</option>
              </SelectField>*/}

            <Select
                options={options}
                name={field.name}
                onChange={(option) => setValue(option.value)}
                instanceId={props.iid}
            />

        </div>
    );
};

//export default DropDown;

export const FormikSelect4 = ({
    options,
    field,
    form,
    labelField,
    valueField,
    readOnly
}) => (
    <Select
        isDisabled={readOnly}
        classNamePrefix="react-select"
        options={options}
        name={field.name}
        value={options ? options.find(option => option[valueField] === field.value) : ''}
        onChange={(option) => form.setFieldValue(field.name, option[valueField])}
        onBlur={field.onBlur}
        getOptionLabel={(option) => `${option[labelField]}`}
        getOptionValue={(option) => `${option[valueField]}`}
    />
)

export const FormikDate = ({
    field,
    form,
}) => (
    <DatePicker
        name={field.name}
        value={field.value}
        onChange={(date) => form.setFieldValue(field.name, date)}
        onBlur={field.onBlur}
    />
)

export const CustomDateInput = forwardRef(
    (
        { value, onClick, isInvalid, isValid, formControlProps, errorMessage },
        ref
    ) => {
        return (
            <>
                <Form.Control
                    ref={ref}
                    isInvalid={isInvalid}
                    isValid={isValid}
                    value={value}
                    onClick={onClick}
                    {...formControlProps}
                />
            </>
        );
    }
);