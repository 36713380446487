import { parseDate } from 'helpers/utils';
import api from './api'

class LoanAccountService {

    // Loan Accounts

    getAllLoans() {
        return api.get("/LoanAccount/getall");
    }

    getByStatus(status) {

        return api.get("/LoanAccount/getByStatus", {
            params: {
                status: status
            }
        });
    }

    getLoanAccountById(id) {
        return api.get("/LoanAccount/get", {
            params: {
                id: id
            }
        });
    }

    createLoanAccount(accountName, details, loanAmount, interestRate, loanTerm, installmentAmount, startDate, endDate, repaymentFrequency) {

        const startDateStr = parseDate(startDate);
        const endDateStr = parseDate(endDate);

        return api.post("/LoanAccount/create", {
            accountName,
            details,
            loanAmount,
            interestRate,
            loanTerm,
            installmentAmount,
            startDate: startDateStr,
            endDate: endDateStr,
            repaymentFrequency
        })
    }

    updateLoanAccount(id, accountName, details, loanAmount, interestRate, loanTerm, installmentAmount, startDate, endDate, repaymentFrequency) {

        const startDateStr = parseDate(startDate);
        const endDateStr = parseDate(endDate);

        return api.post("/LoanAccount/update", {
            id,
            accountName,
            details,
            loanAmount,
            interestRate,
            loanTerm,
            installmentAmount,
            startDate: startDateStr,
            endDate: endDateStr,
            repaymentFrequency
        })
    }

    deleteExpense(id) {
        return api.post("/LoanAccount/delete", {
            id
        })
    }


}

export default new LoanAccountService();