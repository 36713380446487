import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import AppContext from 'context/Context';
import DatePicker from 'react-datepicker';

const AddExpensesModal = ({
    setIsOpenScheduleModal,
    isOpenScheduleModal,
    calendarDispatch,
    initialEvents,
    scheduleStartDate,
    setScheduleStartDate,
    scheduleEndDate,
    setScheduleEndDate
}) => {
    const {
        config: { isDark }
    } = useContext(AppContext);

    const [formData, setFormData] = useState({});

    const handleClose = () => {
        setIsOpenScheduleModal(!isOpenScheduleModal);
    };

    const handleChange = ({ target }) => {
        let name = target.name;
        let value = name === 'allDay' ? target.checked : target.value;
        setFormData({ ...formData, [name]: value });
    };
    const handleSubmit = e => {
        e.preventDefault();
        calendarDispatch({
            type: "ADD_EVENT",
            payload: [...initialEvents, { id: uuid(), ...formData }]
        });
        setIsOpenScheduleModal(false);
    };

    useEffect(() => {
        if (isOpenScheduleModal) {
            setFormData({
                ...formData,
                start: scheduleStartDate,
                end: scheduleEndDate
            });
        } else {
            setScheduleStartDate(null);
            setScheduleEndDate(null);
        }
    }, [isOpenScheduleModal, scheduleStartDate, scheduleEndDate]);

    return (
        <Modal
            show={isOpenScheduleModal}
            onHide={handleClose}
            contentClassName="border"
            backdrop="static"
            centered
        >
            <Form onSubmit={handleSubmit}>
                <Modal.Header
                    closeButton
                    closeVariant={isDark ? 'white' : undefined}
                    className="bg-light px-x1 border-bottom-0"
                >
                    <Modal.Title as="h5"> New Expenses</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-x1">
                    <Form.Group className="mb-3" controlId="titleInput">
                        <Form.Label className="fs-0">Amount</Form.Label>
                        <Form.Control
                            type="number"
                            name="amount"
                            required
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="label">
                        <Form.Label className="fs-0">Category</Form.Label>
                        <Form.Select name="className" onChange={handleChange}>
                            <option>None</option>
                            <option value="bg-soft-info">Groceries</option>
                            <option value="bg-soft-danger">Restaurant</option>
                            <option value="bg-soft-warning">Transport</option>
                            <option value="bg-soft-success">Medicins</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="titleInput">
                        <Form.Label className="fs-0">Notes</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            name="notes"
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="startDate">
                        <Form.Label className="fs-0">Date</Form.Label>
                        <DatePicker
                            selected={scheduleStartDate}
                            onChange={date => {
                                setScheduleStartDate(date);
                                setFormData({ ...formData, start: date });
                            }}
                            className="form-control"
                            placeholderText="MM-DD-YYYY H:M"
                            dateFormat="MM-dd-yyyy h:mm aa"
                            showTimeSelect
                        />
                    </Form.Group>


                    <Form.Group className="mb-3" controlId="label">
                        <Form.Label className="fs-0">Pay By</Form.Label>
                        <Form.Select name="className" onChange={handleChange}>
                            <option>None</option>
                            <option value="bg-soft-info">Tanvi Cash</option>
                            <option value="bg-soft-danger">Vinayak Cash</option>
                            <option value="bg-soft-warning">ICICI Bank</option>
                            <option value="bg-soft-success">Axis Card</option>
                        </Form.Select>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer className="bg-light px-x1 border-top-0">

                    <Button
                        variant="default"
                        type="button"
                        onClick={handleClose}
                        className="px-4 mx-0"
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        type="submit"
                        onClick={handleClose}
                        className="px-4 mx-0"
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

AddExpensesModal.propTypes = {
    setIsOpenScheduleModal: PropTypes.func.isRequired,
    isOpenScheduleModal: PropTypes.bool.isRequired,
    setInitialEvents: PropTypes.func,
    initialEvents: PropTypes.array,
    scheduleStartDate: PropTypes.instanceOf(Date),
    setScheduleStartDate: PropTypes.func.isRequired,
    scheduleEndDate: PropTypes.instanceOf(Date),
    setScheduleEndDate: PropTypes.func.isRequired
};

export default AddExpensesModal;
