import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import { Card, Col, Row, Nav, Tab, Form } from 'react-bootstrap';
import { leadsData } from 'data/dashboard/default';
import FalconCardFooterLink from 'components/common/FalconCardFooterLink';
import classNames from 'classnames';
import FalconCardHeader from 'components/common/FalconCardHeader';
import SimpleBarReact from 'simplebar-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SpendsByCategoryChart from './spends-by-category/SpendsByCategoryChart';

const colors2 = ["#ea5545", "#f46a9b", "#ef9b20", "#edbf33", "#ede15b", "#bdcf32", "#87bc45", "#27aeef", "#b33dc6"];


const LeadItem = ({ item, color, isLast }) => {
    return (
        <Flex
            justifyContent="between"
            alignItems="center"
            className={classNames('border-bottom py-3', {
                'border-bottom-0 pb-0': isLast
            })}
        >
            <Flex>
                <FontAwesomeIcon
                    icon="circle"
                    color={`${color}`}
                    className={`fs--2 me-2`}
                />
                <h6 className="mb-0" style={{ color: color }} >{item.name}</h6>
            </Flex>
            {/* <p className="fs--1 text-500 mb-0 fw-semi-bold">{item.target}</p> */}
            <h6 className="text-700 mb-0">{item.value}</h6>
        </Flex>
    );
};

const NavItem = ({ item }) => {
    return (
        <Nav.Item as="li">
            <Nav.Link
                className="mb-0 py-3 cursor-pointer"
                eventKey={item.toLowerCase()}
            >
                {item}
            </Nav.Link>
        </Nav.Item>
    );
};

const SpendsCharts = ({ categoryData, groupData }) => {

    console.log('SpendsBygroup-data', groupData);
    const [navItems] = useState(['Spends by Group', 'Spends by Category']);

    return (
        <Card className="h-100">

            <Tab.Container id="audience-tab" defaultActiveKey="spends by group">
                <Card.Header
                    as={Flex}
                    justifyContent="between"
                    alignItems="center"
                    className="ps-0 py-0 border-bottom"
                >
                    <Nav
                        as="ul"
                        className="nav-tabs border-0 flex-nowrap chart-tab tab-active-caret"
                    >

                        {navItems.map(item => (
                            <NavItem item={item} key={item} />
                        ))}
                    </Nav>
                </Card.Header>

                <Card.Body>
                    <Row className="g-1">

                        <Col xxl={12}>
                            <Tab.Content>
                                <Tab.Pane unmountOnExit eventKey="spends by group">
                                    <Row className="g-1">
                                        <Col md={5} className="mb-xxl-1">
                                            <SpendsByCategoryChart data={groupData} />
                                        </Col>
                                        <Col md={7}>
                                            <SimpleBarReact className='expenses-chart-items'>
                                                <hr className="mx-ncard mb-0 d-md-none d-xxl-block" />

                                                {groupData.map((item, index) => (

                                                    <LeadItem
                                                        key={item.id}
                                                        item={item}
                                                        color={colors2[index]}
                                                        isLast={index === leadsData.length - 1}
                                                    />
                                                ))}
                                            </SimpleBarReact>
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane unmountOnExit eventKey="spends by category">
                                    <Row className="g-1">
                                        <Col md={5} className="mb-xxl-1">
                                            <SpendsByCategoryChart data={categoryData} />
                                        </Col>
                                        <Col md={7}>
                                            <SimpleBarReact className='expenses-chart-items'>
                                                <hr className="mx-ncard mb-0 d-md-none d-xxl-block" />

                                                {categoryData.map((item, index) => (
                                                    <LeadItem
                                                        key={item.id}
                                                        item={item}
                                                        color={colors2[index]}
                                                        isLast={index === leadsData.length - 1}
                                                    />
                                                ))}
                                            </SimpleBarReact>
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Card.Body>
            </Tab.Container>

        </Card>
    );
};

LeadItem.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        title: PropTypes.string.isRequired,
        img: PropTypes.string.isRequired,
        amount: PropTypes.string.isRequired
    }),
    isLast: PropTypes.bool.isRequired
};

export default SpendsCharts;
