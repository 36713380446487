import api from './api'

class DashboardService {

    getDashboard1() {
        return api.get("/Dashboard/getDashboard1");
    }

    getMonthDashboard(interval, date) {
        return api.get("/Dashboard/getMonthDashboard", {
            params: {
                interval: interval,
                date: date
            }
        });
    }

}

export default new DashboardService();