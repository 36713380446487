import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart, PieChart } from 'echarts/charts';
import {
    GridComponent,
    LegendComponent,
    TitleComponent,
    TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LineChart,
    CanvasRenderer,
    LegendComponent,
    PieChart
]);
const variants = ["progress-gradient", "soft-primary", "soft-info", "info", "primary", "warning"];
const colors = ["#e60049", "#0bb4ff", "#50e991", "#e6d800", "#9b19f5", "#ffa300", "#dc0ab4", "#b3d4ff", "#00bfa0"];
const colors2 = ["#ea5545", "#f46a9b", "#ef9b20", "#edbf33", "#ede15b", "#bdcf32", "#87bc45", "#27aeef", "#b33dc6"];

const getOptions = (data) => ({


    color: colors2,
    tooltip: {
        trigger: 'item',
        padding: [7, 10],
        backgroundColor: getColor('gray-100'),
        borderColor: getColor('gray-300'),
        textStyle: { color: getColor('dark') },
        borderWidth: 1,
        transitionDuration: 0,
        formatter: function (params) {
            return `<strong>${params.data.name}:</strong> ${params.percent}%`;
        }
    },
    legend: { show: false },
    series: [
        {
            name: 'Total',
            type: 'pie',
            radius: ['100%', '67%'],
            avoidLabelOverlap: false,
            itemStyle: {
                borderWidth: 2,
                borderColor: getColor('gray-100')
            },
            label: {
                show: false
            },
            emphasis: {
                scale: false
            },
            labelLine: { show: false },
            data: data
            // data: [
            //     { value: 60, name: 'Email' },
            //     { value: 30, name: 'Social' },
            //     { value: 10, name: 'Call' },
            //     { value: 120, name: 'Other' }
            // ]
        }
    ]
});

const ExpensesChart = ({ data }) => {
    const chartRef = useRef(null);
    console.log('data', data);

    return (
        <div className="position-relative py-2">
            <ReactEChartsCore
                ref={chartRef}
                echarts={echarts}
                option={getOptions(data)}
                style={{ height: '12.5rem' }}
            />
            {/* <div className="position-absolute top-50 start-50 translate-middle text-center">
                <p className="fs--1 mb-0 text-400 font-sans-serif fw-medium">Total</p>
                <p className="fs-3 mb-0 font-sans-serif fw-medium mt-n2">15.6k</p>
            </div> */}
        </div>
    );
};

ExpensesChart.propTypes = {
    data: PropTypes.arrayOf(PropTypes.array)
};

export default ExpensesChart;
