import React, { useEffect, useState } from "react";
import TokenService from 'services/tokenService';
import EventBus from "common/eventBus";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [pending, setPending] = useState(true);

    useEffect(() => {
        // app.auth().onAuthStateChanged((user) => {
        // });

        //setCurrentUser("Vinayak Rahate")

        var token = TokenService.getLocalAccessToken();
        //console.log("AuthProvider: " + token);
        if (token) {
            //setAuthenticated2(true);
            setCurrentUser("Vinayak Rahate")
            //console.log("useEffect: Authenticated");
        }
        setPending(false)

        EventBus.on("logout", () => {
            TokenService.removeUser();
            setCurrentUser(null);
        });

        EventBus.on("logout", () => {
            setCurrentUser(null);
        });

    }, []);

    function logout() {
        setCurrentUser(null);
    }

    if (pending) {
        return <>Loading...</>
    }

    return (
        <AuthContext.Provider
            value={{
                currentUser
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};