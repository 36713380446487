import { parseDate } from 'helpers/utils';
import api from './api'

class ExpenseService {


    // Money Accounts

    getExpenses() {
        return api.get("/Expense/getall");
    }

    getByDates(fromDate, toDate) {

        const fromDateStr = parseDate(fromDate);
        const toDateStr = parseDate(toDate);

        return api.get("/Expense/getByDates", {
            params: {
                fromDate: fromDateStr,
                toDate: toDateStr
            }
        });
    }

    getExpenseById(id) {
        return api.get("/Expense/get", {
            params: {
                id: id
            }
        });
    }

    createExpense(expenseDate, expenseCategoryId, amount, description, moneyAccountId, isEssential) {
        return api.post("/Expense/create", {
            expenseDate,
            expenseCategoryId,
            amount,
            description,
            moneyAccountId,
            isEssential
        })
    }

    updateExpense(id, expenseDate, expenseCategoryId, amount, description, moneyAccountId, isEssential) {
        return api.post("/Expense/update", {
            id,
            expenseDate,
            expenseCategoryId,
            amount,
            description,
            moneyAccountId,
            isEssential
        })
    }

    deleteExpense(id) {
        return api.post("/Expense/delete", {
            id
        })
    }


}

export default new ExpenseService();