import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Form } from 'react-bootstrap';
import Select from 'react-select'

import { v4 as uuid } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import AppContext from 'context/Context';
import DatePicker from 'react-datepicker';
import { Formik, Field, Form as FormikForm } from 'formik';
import { FormTextField, FormTextAreaField, FormSelectField, FormikSelect, FormSelectField2, DropDown, FormikSelect3, FormikSelect4 } from 'helpers/bootstrap-formlk';
import UserService from 'services/userService';

//https://codesandbox.io/s/react-bootstrap-formik-pb831?file=/src/form-field.js


const EditExpenseCategory = ({
    data,
    isDialogOpened,
    onCloseDialog
}) => {
    const {
        config: { isDark }
    } = useContext(AppContext);

    const INITIAL_VALUES = { id: 0, categoryName: '', description: '', expenseGroupId: 0 };
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState(null);
    const [expenseGroup, setExpenseGroup] = useState([]);

    function defaultState() {
        setError('');
    }


    useEffect(() => {
        defaultState();

        if (data) {
            loadExpenseGroups();
            setFormData(data);
        }

    }, [data]);


    function handleClose(isSubmitted) {
        onCloseDialog(isSubmitted);
    }

    function loadExpenseGroups() {
        try {

            UserService.getExpenseGroup().then(
                response => {
                    if (response.data) {
                        //console.log('groupdata', response.data);
                        setExpenseGroup(response.data);
                    }
                },
                error => {

                }
            )
        } catch (err) {

        } finally {
        }
    }

    function checkDefaultValue() {

    }

    const submit = async (values) => {

        try {
            setIsLoading(true);

            UserService.updateExpenseCategory(values.id, values.categoryName, values.description, values.expenseGroupId).then(
                response => {
                    handleClose(true);
                },
                error => {
                    console.log("found err " + error);
                    setError(error);
                }
            )
        } catch (err) {
            setError(err);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal
            show={isDialogOpened}
            onHide={e => handleClose(false)}
            contentClassName="border"
            backdrop="static"
            centered
        >
            <Formik
                initialValues={formData}
                enableReinitialize={true}
                onSubmit={submit}>

                <FormikForm >
                    <Modal.Header
                        closeButton
                        closeVariant={isDark ? 'white' : undefined}
                        className="bg-light px-x1 border-bottom-0"
                    >
                        <Modal.Title as="h5"> Edit Expenses Category</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-x1">
                        {
                            error && <>
                                <div className="d-block bg-danger p-2 text-white mt-3">{error}</div>
                                <br />
                            </>
                        }
                        <Form.Group className="mb-3" controlId="formCategoryName">
                            <Form.Label className="fs-0">Category Name</Form.Label>
                            <FormTextField name="categoryName" type="text" />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formExpenseGroupId">
                            <Form.Label className="fs-0">Expense Group</Form.Label>
                            <Field name='expenseGroupId' component={FormikSelect4} options={expenseGroup} labelField='groupName' valueField='id' />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formDescription">
                            <Form.Label className="fs-0">Description</Form.Label>
                            <FormTextAreaField name="description" rows={3} />
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer className="bg-light px-x1 border-top-0">

                        <Button
                            variant="default"
                            type="button"
                            onClick={e => handleClose(false)}
                            className="px-4 mx-0"
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            type="submit"
                            //onClick={handleClose}
                            className="px-4 mx-0 ms-2"
                        >
                            Save
                        </Button>
                    </Modal.Footer>
                </FormikForm>

            </Formik>
        </Modal>
    );
};

EditExpenseCategory.propTypes = {
    data: PropTypes.object.isRequired,
    isDialogOpened: PropTypes.bool.isRequired,
    onCloseDialog: PropTypes.func.isRequired
};

export default EditExpenseCategory;
