import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { Link } from 'react-router-dom';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';

const columns = [
    {
        accessor: 'categoryName',
        Header: 'Category',
        Cell: rowData => (
            <Link to="#!" className="text-primary fw-semi-bold">
                {rowData.row.original.categoryName}
            </Link>
        )
    },

    {
        accessor: 'amount',
        Header: 'Amount',
        headerProps: {
            className: 'text-end'
        },
        cellProps: {
            className: 'text-end'
        }
    },
    {
        accessor: 'percent',
        Header: 'Percent',
        headerProps: {
            className: 'text-end'
        },
        cellProps: {
            className: 'text-end'
        },
        Cell: rowData => `${rowData.row.original.percent} %`
    }
];

const CategorySummary = ({ tableData, perPage = 7 }) => {

    //console.log("category data", tableData);

    return (
        <AdvanceTableWrapper
            columns={columns}
            data={tableData}
            sortable
            pagination
            perPage={perPage}
        >
            <Card className="h-100">
                <Card.Header>
                    <Row className="flex-between-center">
                        <Col xs="auto" sm={6} lg={7}>
                            <h6 className="mb-0 text-nowrap py-2 py-xl-0">
                                Category wise spends
                            </h6>
                        </Col>
                        {/* <Col xs="auto" sm={6} lg={5}>
                            <AdvanceTableSearchBox table placeholder="Search" />
                        </Col> */}
                    </Row>
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            className: 'fs--1 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableFooter
                        rowCount={tableData.length}
                        table
                        rowInfo
                        navButtons
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

CategorySummary.propTypes = {
    tableData: PropTypes.arrayOf(
        PropTypes.shape({
            categoryName: PropTypes.string.isRequired,
            amount: PropTypes.number.isRequired,
            percent: PropTypes.number.isRequired,
        })
    ).isRequired,
    perPage: PropTypes.number
};

export default CategorySummary;
