
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import TotalLoans from 'components/dashboards/loans/TotalLoans'
import { totalLoans, newLoanData, newJsonData, monthlyExpensesData, statsData, dealForecastData } from 'data/dashboard/default'
import DashboardService from 'services/DashboardService';
import { error, inDateRange } from 'is_js';
import DatewiseGrid from './DatewiseGrid'
import CategorySummary from './CategorySummary';
import Greetings from './Greetings';

import StatisticsCard from '../StatisticsCard';
import bg1 from 'assets/img/icons/spot-illustrations/corner-1.png';
import bg2 from 'assets/img/icons/spot-illustrations/corner-2.png';



const DayWiseDahboard = () => {
    const [error, setError] = useState();

    const [dashboardData, setDashboardData] = useState();
    const [daywiseGridData, setDaywiseGridData] = useState([]);
    const [categoryWiseData, setCategoryWiseData] = useState([]);

    const [totalExpenses, setTotalExpenses] = useState(0);
    const [totalTransactions, setTotalTransactions] = useState(0);

    function onIntervalChange(interval, date) {
        console.log("interval change", interval);
        console.log("date change", date);

        loadData(interval, date);
    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }


    useEffect(() => {
        //console.log("getMonthDashboard call");

        try {

            loadData("Day", new Date())

        } catch (err) {
            setError(err);

        } finally {

        }

    }, []);


    function loadData(interval, date) {

        const dateStr = formatDate(date);

        DashboardService.getMonthDashboard(interval, date).then(
            response => {
                //console.log("getMonthDashboard response received", response.data);
                if (response.data) {
                    var data = response.data;
                    //console.log("getMonthDashboard categorywise wise", data.categoryWise);

                    setTotalExpenses(data.totalExpenses);
                    setTotalTransactions(data.totalTransactions);

                    setDaywiseGridData(data.daywise);
                    setCategoryWiseData(data.categoryWise);


                }
            },
            error => {
                setError(error);
            }
        )
    }

    return (
        <>
            {
                error && <>
                    <div className="d-block bg-danger p-2 text-white mt-3">{error}</div>
                    <br />
                </>
            }
            <Greetings onIntervalChange={onIntervalChange} />
            <Row className="g-3 mb-3">

                <Col key="totalExpenses" sm={6}>
                    <StatisticsCard stat={{
                        title: 'Total Expenses',
                        value: totalExpenses,
                        decimal: true,
                        suffix: 'k',
                        prefix: '',
                        valueClassName: 'text-warning',
                        badgeBg: 'warning',
                        badgeText: '12.5%',
                        link: '/e-commerce/customers',
                        linkText: 'See all',
                        image: bg1
                    }} style={{ minWidth: '12rem' }} />
                </Col>
                <Col key="totalTransactions" sm={6}>
                    <StatisticsCard stat={
                        {
                            title: 'Total Transactions',
                            value: totalTransactions,
                            decimal: false,
                            suffix: '',
                            prefix: '',
                            valueClassName: 'text-info',
                            badgeBg: 'info',
                            badgeText: '5%',
                            link: '/e-commerce/orders/order-list',
                            linkText: 'All orders',
                            image: bg2
                        }
                    } style={{ minWidth: '12rem' }} />
                </Col>
            </Row>
            <Row className='g-3 mb-3'>
                <Col lg={12}>
                    <CategorySummary tableData={categoryWiseData} />
                </Col>
            </Row>
        </>
    )
}

export default DayWiseDahboard;
