import React, { useEffect, useState, forwardRef } from 'react';
import Flex from 'components/common/Flex';
import { Card, Col, Row, Form } from 'react-bootstrap';
import barChart from 'assets/img/illustrations/crm-bar-chart.png';
import lineChart from 'assets/img/illustrations/crm-line-chart.png';
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Greetings = ({ onIntervalChange, onDateChange }) => {

    const intervals = ["Day", "Month", "Year"];
    const [interval, setInterval] = useState("Month");
    const [date, setDate] = useState(new Date());

    return (
        <Card className="bg-100 shadow-none border mb-3">
            <Card.Body className="py-0">
                <Row className="g-0 justify-content-between">
                    <Col sm="auto">
                        <Flex alignItems="center">
                            <img src={barChart} width={90} alt="..." className="ms-n4" />
                            <div>
                                <h6 className="text-primary fs--1 mb-0">Welcome to </h6>
                                <h4 className="text-primary fw-bold mb-0">
                                    Main
                                    <span className="text-info fw-medium"> Dashboard</span>
                                </h4>
                            </div>
                            <img
                                src={lineChart}
                                width={140}
                                alt="..."
                                className="ms-n4 d-md-none d-lg-block"
                            />
                        </Flex>
                    </Col>
                    <Col md="auto" className="mb-3 mb-md-0">
                        <Row className="g-3 gy-md-0 h-100 align-items-center">
                            <Col xs="auto">
                                <h6 className="text-700 mb-0 text-nowrap">Showing Data For:</h6>
                            </Col>
                            {/* <Col md="auto">
                                <Form.Select
                                    size="sm"
                                    value={interval}
                                    onChange={e => {
                                        setInterval(e.target.value);
                                        onIntervalChange(e.target.value, date);
                                    }}
                                    className="me-2"
                                >
                                    {intervals.map((month, index) => (
                                        <option value={month} key={month}>
                                            {month}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Col> */}
                            <Col md="auto">
                                {interval == "Day" &&
                                    <DatePicker
                                        selected={date}
                                        onChange={(date) => {
                                            setDate(date);
                                            onIntervalChange(interval, date);
                                        }}
                                        dateFormat="dd/MM/yyyy"
                                        customInput={<DateInput />}
                                    />
                                }
                                {interval == "Month" &&
                                    <DatePicker
                                        selected={date}
                                        onChange={(date) => {
                                            setDate(date);
                                            onIntervalChange(interval, date);
                                        }}
                                        dateFormat="MMM yyyy"
                                        customInput={<DateInput />}
                                        showMonthYearPicker
                                    />
                                }
                                {interval == "Year" &&
                                    <DatePicker
                                        selected={date}
                                        onChange={(date) => {
                                            setDate(date);
                                            onIntervalChange(interval, date);
                                        }}
                                        dateFormat="yyyy"
                                        customInput={<DateInput />}
                                        showYearPicker
                                    />
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

const DateInput = forwardRef(({ value, onClick }, ref) => (
    <div className="position-relative">
        <Form.Control
            size="sm"
            ref={ref}
            onClick={onClick}
            value={value}
            className="ps-4"
            onChange={e => {
                console.log({ e });
            }}
        />
        <FontAwesomeIcon
            icon="calendar-alt"
            className="text-primary position-absolute top-50 translate-middle-y ms-2"
        />
    </div>
));

export default Greetings;
