import React from 'react';
import { Card, Col, ProgressBar, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import CardDropdown from 'components/common/CardDropdown';
import { dealForecastData } from 'data/dashboard/default'
import classNames from 'classnames';
import Flex from 'components/common/Flex';

const PayeeBar = ({ data }) => {
    const total = data.reduce((acc, data) => acc + data.amount, 0);

    const variants = ["progress-gradient", "soft-primary", "soft-info", "info"];

    return (
        <Card className="h-100">
            <FalconCardHeader
                title="Spend By"
                titleTag="h6"
                className="border-200 border-bottom py-2"
                endEl={<CardDropdown />}
            />
            <Card.Body as={Flex} alignItems="center">
                <div className="w-100">
                    <h3 className="text-700 mb-4"></h3>
                    {/* <span className="mb-6"></span> */}
                    <ProgressBar
                        className="font-sans-serif fs--1 fw-medium mt-xxl-auto rounded-3 overflow-visible mt-"
                        style={{ height: 20 }}
                    >
                        {data.map((item, index) => (
                            <ProgressBar
                                variant={variants[index]}
                                now={(item.amount / total) * 100}
                                key={item.title}
                                className={classNames('overflow-visible position-relative', {
                                    'rounded-end rounded-pill': index === 0,
                                    'rounded-start rounded-pill':
                                        index === data.length - 1,
                                    ' border-end border-white border-2':
                                        index !== data.length - 1
                                })}
                                label={
                                    <span
                                        className="text-700 position-absolute"
                                        style={{ top: -30 }}
                                    >
                                        {(item.amount / 1000).toFixed(1)}k
                                    </span>
                                }
                            />
                        ))}
                    </ProgressBar>
                    <Row className="fs--1 fw-semi-bold text-500 mt-3 g-0">
                        {data.map((item, index) => (
                            <Col
                                xs="auto"
                                key={index}
                                as={Flex}
                                alignItems="center"
                                className="pe-3"
                            >
                                <span className={`dot bg-${variants[index]}`}></span>
                                <span>{item.title}</span>
                                <span className="d-none d-md-inline-block d-lg-none d-xxl-inline-block">
                                    ({((item.amount / total) * 100).toFixed(1)}%)
                                </span>
                            </Col>
                        ))}
                    </Row>
                </div>
            </Card.Body>
        </Card>
    );
};

export default PayeeBar;
