import React, { useEffect } from "react";
import Flex from "components/common/Flex";
import LoginForm from "./LoginForm";
import { Card, Col, Row } from 'react-bootstrap';
import Logo from 'components/common/Logo';
import Section from 'components/common/Section';
import { Link } from 'react-router-dom';
import AuthService from 'services/authService';
import EventBus from 'common/eventBus';

const Login = ({ setLoggedIn }) => {

    useEffect(() => {
        AuthService.logout();
    }, []);

    return (
        <>
            <Section className="py-0">
                <Row className="flex-center min-vh-100 py-6">
                    <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4">
                        <Logo />
                        <Card>
                            <Card.Body className="p-4 p-sm-5">
                                <Flex justifyContent='between' alignItems='center' className='mb-2'>
                                    <h5>Log in</h5>
                                    <p className="fs--1 text-600 mb-0">
                                        or <Link to="/authentication/simple/register">Create an account</Link>
                                    </p>
                                </Flex>
                                <LoginForm setLoggedIn={setLoggedIn} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Section>

        </>
    )
};

export default Login;