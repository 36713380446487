import React, { useState, useEffect } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { transactionData } from 'data/transactionsData';
import CardDropdown from 'components/common/CardDropdown';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import TransactionsTableHeader from './TransactionsTableHeader';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AddExpensesModal from './AddExpenses';

const columns = [
    {
        accessor: 'transactionDate',
        Header: 'Date',
        // Cell: rowData => {
        //     const { transactionDate } = rowData.row.original;
        //     return <h5 className="mb-0 fs--1">{transactionDate}</h5>
        // }
    },
    {
        accessor: 'category',
        Header: 'Category',
        Cell: rowData => {
            const { category } = rowData.row.original;
            return <h5 className="mb-0 fs--1">{category}</h5>
        }
    },
    {
        accessor: 'notes',
        Header: 'Notes',
        headerProps: { style: { minWidth: '200px' }, className: 'ps-5' },
        cellProps: { className: 'ps-5' }
    },
    {
        accessor: 'amount',
        Header: 'Amount',
        headerProps: { className: 'text-end' },
        cellProps: { className: 'text-end' },
        Cell: rowData => `${rowData.row.original.amount}`
    },
    {
        accessor: 'none',
        Header: '',
        disableSortBy: true,
        cellProps: {
            className: 'text-end'
        },
        Cell: () => {
            return (
                <CardDropdown iconClassName="fs--1">
                    <div className="py-2">
                        <Dropdown.Item href="#!">Edit</Dropdown.Item>
                        <Dropdown.Item href="#!">Delete</Dropdown.Item>
                    </div>
                </CardDropdown>
            );
        }
    }
];

const Transactions = () => {




    const [transactions] = useState(transactionData);

    const [isOpenScheduleModal, setIsOpenScheduleModal] = useState(false);
    const [scheduleStartDate, setScheduleStartDate] = useState();
    const [scheduleEndDate, setScheduleEndDate] = useState();


    return (
        <>
            <AdvanceTableWrapper
                columns={columns}
                data={transactions}
                selection
                sortable
                pagination
                perPage={10}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <TransactionsTableHeader
                            setIsOpenScheduleModal={setIsOpenScheduleModal}
                            isOpenScheduleModal={isOpenScheduleModal}
                            table />
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination table />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>

            <AddExpensesModal
                isOpenScheduleModal={isOpenScheduleModal}
                setIsOpenScheduleModal={setIsOpenScheduleModal}
                scheduleStartDate={scheduleStartDate}
                scheduleEndDate={scheduleEndDate}
                setScheduleStartDate={setScheduleStartDate}
                setScheduleEndDate={setScheduleEndDate}
            />
        </>
    );
};

export default Transactions;
