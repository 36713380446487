import api from './api';
import TokenService from "./tokenService";
import EventBus from "common/eventBus";

class AuthService {
    login(userName, password) {
        return api.post("/auth/login", {
            userName,
            password,
        })
            .then(response => {
                //console.log(response);

                if (response.data.accessToken) {
                    TokenService.setUser(response.data);
                }

                return response.data;
            })
            .catch(error => {
                //console.log(error);
                // if (error.response) {
                //     return Promise.reject(error.response.data);
                // }
                // return Promise.reject(error.message);
                return Promise.reject(error);

            })
    }

    logout() {
        TokenService.removeUser();
        EventBus.dispatch("logout");
    }

    register(userName, email, password) {
        return api.post("/auth/register", {
            userName,
            email,
            password
        })
    }

    getCurrentUser() {
        return TokenService.getUser();
    }
}

export default new AuthService();
