import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import TotalLoans from './TotalLoans'
import { totalLoans, newLoanData, newJsonData } from 'data/dashboard/default'

const Dashboard = () => {

    var data = newJsonData();

    console.log(data);

    console.log(newLoanData);

    return (
        <>
            <Row className='g-3 mb-3'>

                <Col lg={12}>
                    <TotalLoans data={data} />
                </Col>
            </Row>
        </>
    )
}

export default Dashboard;
