
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import TotalLoans from 'components/dashboards/loans/TotalLoans'
import { totalLoans, newLoanData, newJsonData, monthlyExpensesData, statsData, dealForecastData } from 'data/dashboard/default'
import DashboardService from 'services/DashboardService';
import { error, inDateRange } from 'is_js';
import DatewiseGrid from './DatewiseGrid'
import CategorySummary from './CategorySummary';
import Greetings from './Greetings';

import StatisticsCard from '../StatisticsCard';
import bg1 from 'assets/img/icons/spot-illustrations/corner-1.png';
import bg2 from 'assets/img/icons/spot-illustrations/corner-2.png';
import MoneyAccountStat from './MoneyAccountStat';
import DayWiseExpenseChart from './DayWiseExpenseChart';
import CategoryWiseChart from '../default/CategoryWise';
import CategoryPieChart from './CategoryPieChart';
import StorageStatus from './StorageStatus';
import SpendsByCategory from './spends-by-category/SpendsByCategory';
import SpendsCharts from './SpendsCharts';


const MonthWiseDahboard = () => {
    const [error, setError] = useState();

    const [dashboardData, setDashboardData] = useState();
    const [totalExpenses, setTotalExpenses] = useState(0);
    const [totalTransactions, setTotalTransactions] = useState(0);

    const [daywiseGridData, setDaywiseGridData] = useState([]);
    const [categoryWiseData, setCategoryWiseData] = useState([]);
    const [categoryPieChartData, setCategoryPieChartData] = useState([]);
    const [groupPieChartData, setGroupPieChartData] = useState([]);

    const [cashStat, setCashStat] = useState({ amount: 0, subAmount: 0, percent: 0 });
    const [bankStat, setBankStat] = useState({ amount: 0, subAmount: 0, percent: 0 });
    const [creditCardStat, setCreditCardStat] = useState({ amount: 0, subAmount: 0, percent: 0 });
    const [dayWiseExpenses, setDayWiseExpenses] = useState({ title: [], data: [] });

    function onIntervalChange(interval, date) {
        console.log("interval change", interval);
        console.log("date change", date);

        loadData(interval, date);
    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }


    useEffect(() => {
        //console.log("getMonthDashboard call");

        try {

            loadData("Month", new Date())

        } catch (err) {
            setError(err);

        } finally {

        }

    }, []);


    function loadData(interval, date) {

        const dateStr = formatDate(date);

        DashboardService.getMonthDashboard(interval, dateStr).then(
            response => {
                //console.log("getMonthDashboard response received", response.data);
                if (response.data) {
                    var data = response.data;
                    //console.log("getMonthDashboard categorywise wise", data.categoryWise);

                    setTotalExpenses(data.totalExpenses);
                    setTotalTransactions(data.totalTransactions);
                    setDaywiseGridData(data.daywise);
                    setCategoryWiseData(data.categoryWise);

                    setCashStat(data.cashStat);
                    setBankStat(data.bankStat);
                    setCreditCardStat(data.creditCardStat);
                    setDayWiseExpenses(data.dayWiseChartData);
                    setCategoryPieChartData(data.categoryPieChartData);
                    setGroupPieChartData(data.groupPieChartData);

                    console.log('categoryPieChartData', data.categoryPieChartData);
                }
            },
            error => {
                setError(error);
            }
        )
    }

    return (
        <>
            {
                error && <>
                    <div className="d-block bg-danger p-2 text-white mt-3">{error}</div>
                    <br />
                </>
            }
            <Greetings onIntervalChange={onIntervalChange} />

            <Row className="g-3 mb-3">

                <Col key="totalExpenses" sm={3}>
                    <StatisticsCard stat={{
                        title: 'All Spends',
                        value: totalExpenses,
                        decimal: false,
                        suffix: '',
                        prefix: '₹',
                        valueClassName: 'text-warning',
                        badgeBg: 'warning',
                        badgeText: '100%',
                        link: '/e-commerce/customers',
                        linkText: 'See all',
                        image: bg1,
                        className: "h-md-100"
                    }} style={{ minWidth: '12rem' }} />
                </Col>

                <Col key="byCash" sm={3}>
                    <StatisticsCard stat={
                        {
                            title: 'By Cash',
                            value: cashStat.amount,
                            decimal: false,
                            suffix: '',
                            prefix: '₹',
                            valueClassName: 'text-info',
                            badgeBg: 'info',
                            badgeText: cashStat.percent + '%',
                            link: '/e-commerce/orders/order-list',
                            linkText: 'All orders',
                            image: bg2
                        }
                    } style={{ minWidth: '12rem' }} />
                </Col>
                <Col key="byBank" sm={3}>
                    <StatisticsCard stat={
                        {
                            title: 'By Bank',
                            value: bankStat.amount,
                            decimal: false,
                            suffix: '',
                            prefix: '₹',
                            valueClassName: 'text-info',
                            badgeBg: 'info',
                            badgeText: bankStat.percent + '%',
                            link: '/e-commerce/orders/order-list',
                            linkText: 'All orders',
                            image: bg2
                        }
                    } style={{ minWidth: '12rem' }} />
                </Col>
                <Col key="byCreditCard" sm={3}>
                    <StatisticsCard stat={
                        {
                            title: 'By Credit Card',
                            value: creditCardStat.amount,
                            decimal: false,
                            suffix: '',
                            prefix: '₹',
                            valueClassName: 'text-info',
                            badgeBg: 'info',
                            badgeText: creditCardStat.percent + '%',
                            link: '/e-commerce/orders/order-list',
                            linkText: 'All orders',
                            image: bg2
                        }
                    } style={{ minWidth: '12rem' }} />
                </Col>


            </Row>


            <Row className='g-3 mb-3'>
                {/* <Col md={12}>
                    <StorageStatus data={storageStatus} />
                </Col> */}
                {/* <Col md={12}>
                    <SpendsByCategory data={categoryPieChartData} />
                </Col> */}

                <Col md={12}>
                    <SpendsCharts categoryData={categoryPieChartData} groupData={groupPieChartData} />
                </Col>

                <Col md={12}>
                    <DayWiseExpenseChart data={dayWiseExpenses} />
                </Col>

                {/* <Col lg={12}>
                    <CategoryPieChart />
                </Col> */}
            </Row>
            <Row className='g-3 mb-3'>
                {/* <Col lg={5}>
                    <DatewiseGrid dashboardData={daywiseGridData} />
                </Col> */}
                <Col lg={12}>
                    <CategorySummary tableData={categoryWiseData} />
                </Col>
            </Row>
        </>
    )
}
// Private IP:
// 172.26.15.37
export default MonthWiseDahboard;



export const saleItems = [
    {
        title: 'Cash',
        amount: '15,450',
        subAmount: '13,675',
        type: 'standard',
        percent: 21.8,
        className: 'border-200 border-end '
    },
    {
        title: 'Bank',
        amount: '1,054',
        subAmount: '13,675',
        type: 'warning',
        percent: 21.8,
        className: 'border-200 border-md-200 border-md-end ps-3'
    },
    {
        title: 'Credit Card',
        amount: '$145.65',
        subAmount: '13,675 ',
        type: 'up',
        percent: 21.8,
        className:
            'border-200 border-end border-md-end-0 pt-md-0 ps-md-3'
    }

];

export const storageStatus = [
    {
        name: 'Cash(85%)',
        size: 85,
        color: 'success'
    },
    {
        name: 'Bank(10%)',
        size: 10,
        color: 'info'
    },
    {
        name: 'Credit Card(5%)',
        size: 5,
        color: 'danger'
    },

];