import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App'
import Main from './Main';
import 'helpers/initFA';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('main');
const root = createRoot(container);

root.render(
  // <React.StrictMode>
  //   <Main>
  //     <App />
  //   </Main>
  // </React.StrictMode>

  <Main>
    <App />
  </Main>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
