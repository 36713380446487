import React, { useContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import MainLayout from './MainLayout';
import Dashboard from 'components/dashboards/default';
import DayWiseDahboard from 'components/dashboards/daywise';

import LoanDashboard from 'components/dashboards/loans';

import Customers from 'components/customers/Customers';
import Categories from 'components/categories/Categories';
import Transactions from 'components/transactions/Transactions';
import Login from 'components/Login/Login';
import TokenService from 'services/tokenService';
import AppContext from 'context/Context';
import is from 'is_js';
import { useUser } from 'hooks/useUser';
import { APP_ROUTES } from 'common/constants'
import { Nav } from 'react-bootstrap';
import ExpenseGroup from 'components/expensegroup/ExpenseGroup';
import MoneyAccounts from 'components/moneyAccount/MoneyAccounts';
import Expenses from 'components/expense/Expenses';
import Home from 'components/home/Home';
import MonthWiseDahboard from 'components/dashboards/monthwise';
import MoneyPlan from 'components/moneyPlan/MoneyPlan';
import Loans from 'components/loans/Loans';
import TransactionsSync from 'components/transactions/TransactionsSync';


const Layout = ({ isAuthenticated }) => {

    //console.log(`Layout Authenticated ${isAuthenticated}`);

    const HTMLClassList = document.getElementsByTagName('html')[0].classList;
    const {
        config: { navbarPosition }
    } = useContext(AppContext);

    useEffect(() => {
        if (is.windows()) {
            HTMLClassList.add('windows');
        }
        if (is.chrome()) {
            HTMLClassList.add('chrome');
        }
        if (is.firefox()) {
            HTMLClassList.add('firefox');
        }
        if (is.safari()) {
            HTMLClassList.add('safari');
        }
    }, [HTMLClassList]);

    useEffect(() => {
        if (navbarPosition === 'double-top') {
            HTMLClassList.add('double-top-nav-layout');
        }
        return () => HTMLClassList.remove('double-top-nav-layout');
    }, [navbarPosition]);

    const [loggedIn, setLoggedIn] = useState(false);
    //const [authenticated, setAuthenticated] = useState(false);

    //const { authenticated } = useUser();
    // var password = localStorage.getItem("password");

    // console.log("password is " + password);
    // if (password == "1234") {
    //     //setLoggedIn(true);
    //     Authenticated = true;
    // }

    //var Authenticated = false;

    // var token = TokenService.getLocalAccessToken();
    // if (token) {
    //     Authenticated = true;
    // }

    // console.log("Layout authenticated :" + isAuthenticated);

    //useEffect(() => {
    // var token = TokenService.getLocalAccessToken();
    // console.log("useEffect: " + token);
    // if (token) {
    //     setAuthenticated(true);
    //     console.log("useEffect: Authenticated");
    // }

    //}, []); // The empty dependency array means this effect only runs once (on mount)


    return (
        <>
            <Routes>
                <Route path="/" element={<Home />} />

                <Route path="/login" element={<Login setLoggedIn={setLoggedIn} />} />

                <Route element={<MainLayout isAuthenticated={isAuthenticated} />}>
                    <Route path="/yearwise" element={<Dashboard />} />
                    <Route path="/daywise" element={<DayWiseDahboard />} />
                    <Route path="/default" element={<MonthWiseDahboard />} />

                    <Route path="/loan-dashboard" element={<LoanDashboard />} />
                    {/* <Route path="/expenses" element={<Expenses />} /> */}

                    <Route path="/customers" element={<Customers />} />
                    <Route path="/categories" element={<Categories />} />
                    <Route path="/transactions" element={<Transactions />} />
                    <Route path="/expense-group" element={<ExpenseGroup />} />
                    <Route path="/money-accounts" element={<MoneyAccounts />} />
                    <Route path="/money-plan" element={<MoneyPlan />} />
                    <Route path="/loans" element={<Loans />} />

                    <Route path="/expenses" element={<TransactionsSync />} />


                </Route>
            </Routes>
            {/* <Navigate from="/" to="/login" /> */}
        </>
    )
}

export default Layout;