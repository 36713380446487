import axios from "axios";
import TokenService from "./tokenService";
import AuthService from 'services/authService';

export const apiURL = process.env.NODE_ENV === 'development' ? "http://localhost:5001" : "https://api.spenzzy.com";

const instance = axios.create({
    baseURL: apiURL,
    //baseURL: "http://api.spenzzy.com",
    headers: {
        "Content-Type": "application/json"
    },
});

instance.interceptors.request.use(
    (config) => {
        const accessToken = TokenService.getLocalAccessToken();
        if (accessToken) {
            config.headers["Authorization"] = 'Bearer ' + accessToken;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// instance.interceptors.response.use(

//     (res) => {
//         return res;
//     },
//     async (err) => {

//         const originalConfig = err.config;

//         if (originalConfig.url !== "/auth/login" && err.response) {
//             // Access Token Expired
//             console.log("Access token expired");

//             console.log("originalConfig._retry " + originalConfig._retry);

//             if (err.response.status === 401 && !originalConfig._retry) {
//                 originalConfig._retry = true;

//                 try {
//                     const res = await instance.post("token/refresh", {
//                         accessToken: TokenService.getLocalAccessToken(),
//                         refreshToken: TokenService.getLocalRefreshToken()
//                     });

//                     const { accessToken, refreshToken } = res.data;
//                     TokenService.updateLocalAccessToken(accessToken, refreshToken);

//                     return instance(originalConfig);
//                 } catch (_error) {
//                     console.log("Refresh token expired");
//                     return Promise.reject(_error);
//                 }
//             }

//         }

//         return Promise.reject(err);
//     }

// );

instance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;

        originalConfig._retry = typeof originalConfig._retry === 'undefined' ? 0 : ++originalConfig._retry

        if (originalConfig.url === "/token/refresh") {
            // Refresh token expired
            console.log("Refresh token expired");
            AuthService.logout();
            return Promise.reject(err);
        }

        if (originalConfig.url !== "/auth/login" && err.response) {
            // Access Token was expired
            if (err.response.status === 401) {

                try {
                    const rs = await instance.post("/token/refresh", {
                        accessToken: TokenService.getLocalAccessToken(),
                        refreshToken: TokenService.getLocalRefreshToken()
                    });

                    const { accessToken, refreshToken } = rs.data;
                    TokenService.updateLocalAccessToken(accessToken, refreshToken);

                    return instance(originalConfig);
                } catch (_error) {
                    return Promise.reject(_error);
                }
            }
        }

        console.log("API Catch", err);
        //return Promise.reject(err);

        var errorMessage = "";
        if (err.response) {
            if (isString(err.response.data)) {
                errorMessage = err.response.data;
            }

            if (errorMessage === "") {
                errorMessage = err.message;
            }
        } else {
            errorMessage = err.message;
        }

        return Promise.reject(errorMessage);

    }
);

function isString(obj) {

    if (obj.constructor.name === 'String') {
        return true;
    }

    return false;
}
// instance.interceptors.response.use(response => response, error => {
//     const { response, config } = error

//     if (response.status !== 401) {
//         return Promise.reject(error)
//     }

//     // Use a 'clean' instance of axios without the interceptor to refresh the token. No more infinite refresh loop.
//     return axios.get('/token/refresh', {
//         baseURL,
//         timeout: 30000
//     })
//         .then(() => {
//             // If you are using localStorage, update the token and Authorization header here


//             return axiosInstance(config)
//         })
//         .catch(() => {
//             return Promise.reject(error)
//         })
// })

export default instance;