import { parseDate } from 'helpers/utils';
import api from './api'

class MoneyPlanService {

    getMoneyPlan(monthDate) {
        return api.get("/MoneyPlan/getMoneyPlan", {
            params: {
                monthDate: monthDate
            }
        });
    }

    createMoneyPlan(planDate, accountId, amount, dueDate, notes) {
        return api.post("/MoneyPlan/create", {
            planDate,
            accountId,
            amount,
            dueDate,
            notes
        })
    }
    getMoneyPlanById(id) {
        return api.get("/MoneyPlan/get", {
            params: {
                id: id
            }
        });
    }

    updateMoneyPlan(id, planDate, accountId, amount, dueDate, notes) {
        return api.post("/MoneyPlan/update", {
            id,
            planDate,
            accountId,
            amount,
            dueDate,
            notes
        })
    }

    deleteMoneyPlan(id) {
        return api.post("/MoneyPlan/delete", {
            id
        })
    }

    paidMoneyPlan(id, paidAmount, paidDate) {
        var paiDateStr = parseDate(paidDate);

        return api.post("/MoneyPlan/paid", {
            id,
            paidAmount,
            paidDate: paiDateStr
        })
    }

    generateMoneyPlan(planDate) {
        var planDateStr = parseDate(planDate);

        return api.post("/MoneyPlan/generate", {
            PlanDate: planDateStr
        })
    }
}

export default new MoneyPlanService();