import CardDropdown from 'components/common/CardDropdown';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import * as echarts from 'echarts/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';

import { months, years } from 'data/common';

import {
    GridComponent,
    LegendComponent,
    TitleComponent,
    TooltipComponent,
    GraphicComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    MarkLineComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { capitalize, getColor, rgbaColor } from 'helpers/utils';

echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LineChart,
    CanvasRenderer,
    LegendComponent,
    GraphicComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    MarkLineComponent
]);


const getOptions = (legends, data) => ({
    color: getColor('gray-100'),
    tooltip: {
        trigger: 'axis',
        padding: [7, 10],
        backgroundColor: getColor('gray-100'),
        borderColor: getColor('gray-100'),
        textStyle: { color: getColor('dark') },
        borderWidth: 1,
        formatter: params => {
            const { name, value } = params[0];
            return `${name} : ${value}`;
        },
        transitionDuration: 0
    },

    // dataZoom: [
    //     {
    //         type: 'inside',
    //         start: 80,
    //         end: 100
    //     },
    // ],
    graphic: {
        invisible: data != null,
        type: 'text',
        left: 'center',
        top: 'middle',
        style: { text: "No data available" }
    },
    xAxis: {
        type: 'category',
        data: legends,
        boundaryGap: false,
        axisPointer: {
            lineStyle: {
                color: getColor('gray-300'),
                type: 'dashed'
            }
        },
        splitLine: { show: false },
        axisLine: {
            lineStyle: {
                color: getColor('gray-300'),
                type: 'dashed'
            }
        },
        axisTick: { show: false },
        axisLabel: {
            color: getColor('gray-400'),
            //formatter: value => `${capitalize(month.slice(0, 3))} ${value}`,
            //formatter: value => `${capitalize(month.slice(0, 3))} ${value}`,
            margin: 15
        }
    },
    yAxis: {
        type: 'value',
        axisPointer: { show: false },
        splitLine: {
            lineStyle: {
                color: getColor('gray-300'),
                type: 'dashed'
            }
        },
        boundaryGap: false,
        //boundaryGap: [0, '100%'],
        axisLabel: {
            show: true,
            color: getColor('gray-400'),
            margin: 15
        },
        axisTick: { show: false },
        axisLine: { show: false }
    },
    series: [
        {
            type: 'line',
            data,
            lineStyle: { color: getColor('primary') },
            itemStyle: {
                borderColor: getColor('primary'),
                borderWidth: 2
            },
            // markLine: {
            //     data: [
            //         { name: "New Loan", xAxis: 'Jan 20' },
            //     ],
            // },
            symbol: 'circle',
            symbolSize: 10,
            smooth: false,
            areaStyle: {
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                        {
                            offset: 0,
                            color: rgbaColor(getColor('primary'), 0.2)
                        },
                        {
                            offset: 1,
                            color: rgbaColor(getColor('primary'), 0)
                        }
                    ]
                }
            }
        }
    ],
    grid: { right: 10, left: 0, bottom: 0, top: 10, containLabel: true }
});


const MonthExpenses = ({ data }) => {
    const [month, setMonth] = useState(0);
    const [year, setYear] = useState(years[0]);

    return (



        <Card className="h-100">
            <FalconCardHeader
                title="Monthwise"
                titleTag="h6"
                className="py-3"
                light

            />

            <Card.Body>
                <ReactEChartsCore
                    echarts={echarts}
                    option={getOptions(data.title, data.data)}
                    style={{ height: '18.4375rem' }}
                />
            </Card.Body>

        </Card>
    );
}

MonthExpenses.propTypes = {
    data: PropTypes.object
};


export default MonthExpenses;
