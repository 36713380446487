import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Form } from 'react-bootstrap';
import Select from 'react-select'

import { v4 as uuid } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import AppContext from 'context/Context';
import DatePicker from 'react-datepicker';
import { Formik, Field, Form as FormikForm } from 'formik';
import { FormTextField, FormTextAreaField, FormSelectField, FormikSelect, FormSelectField2, DropDown, FormikSelect3, FormikSelect4 } from 'helpers/bootstrap-formlk';
import UserService from 'services/userService';

//https://codesandbox.io/s/react-bootstrap-formik-pb831?file=/src/form-field.js


const AddExpenseCategory = ({
    data,
    isDialogOpened,
    onCloseDialog
}) => {
    const {
        config: { isDark }
    } = useContext(AppContext);

    const organizerOptions = [
        { value: 1, label: ' Massachusetts Institute of Technology' },
        { value: 2, label: 'University of Chicago' },
        { value: 3, label: 'GSAS Open Labs At Harvard' },
        { value: 4, label: 'California Institute of Technology' }
    ];


    const INITIAL_VALUES = { id: 0, categoryName: '', description: '', expenseGroupId: 0 };
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState(null);
    const [expenseGroup, setExpenseGroup] = useState([]);

    function defaultState() {
        setError('');
    }


    useEffect(() => {
        defaultState();

        if (data) {
            loadExpenseGroups();


            //console.log(data);
            setFormData(data);

        }

    }, [data]);


    function handleClose(isSubmitted) {
        onCloseDialog(isSubmitted);
    }

    function loadExpenseGroups() {
        try {

            UserService.getExpenseGroup().then(
                response => {
                    if (response.data) {
                        setExpenseGroup(response.data);
                    }
                },
                error => {

                }
            )
        } catch (err) {

        } finally {
        }
    }

    function checkDefaultValue() {

    }

    const submit = async (values) => {

        try {
            setIsLoading(true);

            UserService.createExpenseCategory(values.categoryName, values.description, values.expenseGroupId).then(
                response => {
                    handleClose(true);
                },
                error => {
                    setError(error);
                }
            )
        } catch (err) {
            setError(err);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal
            show={isDialogOpened}
            onHide={e => handleClose(false)}
            contentClassName="border"
            backdrop="static"
            centered
        >
            <Formik
                initialValues={formData}
                enableReinitialize={true}
                onSubmit={submit}>

                {({ values, setFieldValue }) => (

                    <FormikForm >
                        <Modal.Header
                            closeButton
                            closeVariant={isDark ? 'white' : undefined}
                            className="bg-light px-x1 border-bottom-0"
                        >
                            <Modal.Title as="h5"> New Expenses Category</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="p-x1">
                            <Form.Group className="mb-3" controlId="formCategoryName">
                                <Form.Label className="fs-0">Category Name</Form.Label>
                                <FormTextField name="categoryName" type="text" />
                            </Form.Group>



                            <Form.Group className="mb-3" controlId="formExpenseGroupId">
                                <Form.Label className="fs-0">Expense Group</Form.Label>
                                {/* {values.expenseGroupId} */}

                                {/* <FormikSelect3
                                    onChange={value => setFieldValue('expenseGroupId', value)}
                                    value={values.expenseGroupId}
                                    options={organizerOptions}
                                /> */}


                                {/* 
                                <Select
                                    name='expenseGroupId'
                                    closeMenuOnSelect={true}
                                    options={organizerOptions}
                                    placeholder='Select...'
                                    classNamePrefix="react-select"
                                    value={values.expenseGroupId}
                                    onChange={value => setFieldValue('expenseGroupId', value)}
                                /> */}

                                {/* <FormSelectField
                                    name="expenseGroupId"
                                >
                                    {organizerOptions.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </FormSelectField> */}

                                {/* <Field as="select" name="expenseGroupId">
                                {organizerOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </Field> */}

                                {/* <FormikSelect
                                selectOptions={organizerOptions}
                                formikFieldName="expenseGroupId"
                                placeholder="Shipping Methods"
                            /> */}


                                {/* <FormSelectField2
                                    name="expenseGroupId"
                                    type="select"
                                >
                                    {expenseGroup.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.groupName}
                                        </option>
                                    ))}
                                </FormSelectField2> */}

                                <Field name='expenseGroupId' component={FormikSelect4} options={expenseGroup} labelField='groupName' valueField='id' />

                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formDescription">
                                <Form.Label className="fs-0">Description</Form.Label>
                                <FormTextAreaField name="description" rows={3} />
                            </Form.Group>

                        </Modal.Body>
                        <Modal.Footer className="bg-light px-x1 border-top-0">

                            <Button
                                variant="default"
                                type="button"
                                onClick={e => handleClose(false)}
                                className="px-4 mx-0"
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                type="submit"
                                //onClick={handleClose}
                                className="px-4 mx-0 ms-2"
                            >
                                Save
                            </Button>
                        </Modal.Footer>
                    </FormikForm>
                )}
            </Formik>
        </Modal>
    );
};

AddExpenseCategory.propTypes = {
    data: PropTypes.object.isRequired,
    isDialogOpened: PropTypes.bool.isRequired,
    onCloseDialog: PropTypes.func.isRequired
};

export default AddExpenseCategory;
