import React, { useState, useEffect } from 'react';
import { Card, Dropdown, Col, Form, Row, Button } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import CardDropdown from 'components/common/CardDropdown';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import LoanAccountService from 'services/LoanAccountService';
import AddExpense from './AddExpense';
import EditExpense from './EditExpense'
import DeleteExpense from './DeleteExpense';
import moment from 'moment';
import MultiDate from 'components/common/MultiDate';


const Loans = () => {

    const INITIAL_VALUES = { id: 0, accountName: "", details: "", loanAmount: 0, interestRate: 0, loanTerm: 0, startDate: moment().toDate(), endDate: moment().toDate() };

    const [ExpenseData, setExpenseData] = useState([]);
    const [error, setError] = useState();

    const [addDialogData, setAddDialogData] = useState(INITIAL_VALUES);
    const [isAddExpenseDialogOpen, setIsOpenAddExpenseDialog] = useState(false);

    const [editDialogData, setEditDialogData] = useState(INITIAL_VALUES);
    const [isEditExpenseDialogOpen, setIsOpenExpenseDialog] = useState(false);

    const [deleteDialogData, setDeleteDialogData] = useState({ id: 0, categoryName: '', command: '' });
    const [isDeleteExpenseDialogOpen, setIsOpenDeleteExpenseDialog] = useState(false);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(new Date());
    const [loanStatus, setLoanStatus] = useState('Active');

    const date = new Date();

    useEffect(() => {

        var fromDate = moment(date).subtract(7, 'day').toDate();

        setStartDate(fromDate);

        loadData(1);

    }, []);

    function loadData(status) {
        try {


            LoanAccountService.getByStatus(status).then(
                response => {

                    if (response.data) {
                        setExpenseData(response.data);
                    }
                },
                error => {
                    setError(error);
                }
            )
        } catch (err) {
            setError(err);
        } finally {
            //setFormLoaded(true);
        }
    }

    function refresh() {
        loadData(1);
    }

    const openAddExpenseDialog = () => {

        setAddDialogData(INITIAL_VALUES)
        setIsOpenAddExpenseDialog(true);
    }

    function handleCloseAddMoneyAcountDialog(isSubmitted) {
        setIsOpenAddExpenseDialog(false);

        if (isSubmitted) {
            refresh();
        }
    }

    const openEditExpenseDialog = (id) => {

        try {

            LoanAccountService.getLoanAccountById(id).then(
                response => {
                    response.data.startDate = moment(response.data.startDate).toDate();
                    response.data.endDate = moment(response.data.endDate).toDate();

                    setEditDialogData(response.data);

                },
                error => {

                }
            )
        } catch (err) {
        } finally {

        }

        setIsOpenExpenseDialog(true);

    }

    function handleCloseEditExpenseDialog(isSubmitted) {
        setIsOpenExpenseDialog(false);

        if (isSubmitted) {
            refresh();
        }
    }

    const openDeleteExpenseDialog = (id, date, category, amount) => {


        setDeleteDialogData({
            id: id,
            date: date,
            category: category,
            amount: amount,
            command: ''
        })

        setIsOpenDeleteExpenseDialog(true);

    }

    function handleCloseDeleteDialog(isSubmitted) {
        setIsOpenDeleteExpenseDialog(false);

        if (isSubmitted) {
            refresh();
        }
    }

    function onDateChange(startDate, endDate) {
        console.log('onDateChange');

        setStartDate(startDate);
        setEndDate(endDate);
        loadData(startDate, endDate);
    }

    const columns = [
        {
            accessor: 'AccountName',
            Header: 'Account Name',
            Cell: rowData => {
                const { accountName } = rowData.row.original;
                return <h5 className="mb-0 fs--1">{accountName}</h5>
            }
        },
        {
            accessor: 'details',
            Header: 'Details'
        },
        {
            accessor: 'loanAmount',
            Header: 'Loan Amount',
            headerProps: { className: 'text-end' },
            cellProps: { className: 'text-end' },
            Cell: rowData => `${rowData.row.original.loanAmount}`
        },
        {
            accessor: 'interestRate',
            Header: 'Interest Rate',
            headerProps: { className: 'text-end' },
            cellProps: { className: 'text-end' },
            Cell: rowData => `${rowData.row.original.interestRate}`
        },
        {
            accessor: 'loanTerm',
            Header: 'Loan Term',
            Cell: rowData => `${rowData.row.original.loanTerm} Months`
        },
        {
            accessor: 'installmentAmount',
            Header: 'EMI',
            Cell: rowData => `${rowData.row.original.installmentAmount}`
        },
        {
            accessor: 'startDate',
            Header: 'Start Date',
            Cell: rowData => {
                const { startDate } = rowData.row.original;
                const formatedDate = moment(startDate).format('DD/MM/yyyy')
                return <>{formatedDate} </>
            }
        },
        {
            accessor: 'endDate',
            Header: 'End Date',
            Cell: rowData => {
                const { endDate } = rowData.row.original;
                const formatedDate = moment(endDate).format('DD/MM/yyyy')
                return <>{formatedDate} </>
            }
        },
        {
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            cellProps: {
                className: 'text-end'
            },
            Cell: (rowData) => {
                const { id, accountName } = rowData.row.original;

                return (
                    <CardDropdown iconClassName="fs--1">
                        <div className="py-2">
                            <Dropdown.Item onClick={() => {
                                openEditExpenseDialog(id);
                            }}>Edit</Dropdown.Item>

                            {/* <Dropdown.Item onClick={() => {
                                openDeleteExpenseDialog(id, expenseDate, expenseCategoryName, amount);
                            }}>Delete</Dropdown.Item> */}
                        </div>
                    </CardDropdown>
                );
            }
        }
    ];


    const ExpensesTableHeader = ({
        selectedRowIds,
        globalFilter,
        setGlobalFilter,
    }) => {
        return (
            <Row className="flex-between-center">
                {/* <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                    <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Expeneses</h5>
                    <AdvanceTableSearchBox
                        className="input-search-width"
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                        placeholder="Search by name"
                    />
                </Col>*/}
                {/* <Col xs="auto" className="pe-0">
                    <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Expeneses</h5>
                </Col> */}
                <Col xs="4" >
                    <AdvanceTableSearchBox
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                        placeholder="Quick search"
                    />
                </Col>
                {/* <Col xs={4} sm="auto" className="ms-auto ps-0">

                </Col> */}
                <Col xs={8} sm="auto" className="ms-auto text-end ps-0">

                    {Object.keys(selectedRowIds).length > 0 ? (
                        <div className="d-flex">
                            <Form.Select size="sm" aria-label="Bulk actions">
                                <option>Bulk Actions</option>
                                <option value="refund">Refund</option>
                                <option value="delete">Delete</option>
                                <option value="archive">Archive</option>
                            </Form.Select>
                            <Button
                                type="button"
                                variant="falcon-default"
                                size="sm"
                                className="ms-2"
                            >
                                Apply
                            </Button>
                        </div>
                    ) : (
                        <div id="orders-actions">

                            <IconButton
                                variant="falcon-primary"
                                size="sm"
                                icon="plus"
                                transform="shrink-3"
                                onClick={() => {
                                    openAddExpenseDialog();
                                }}
                            >
                                <span className="d-none d-sm-inline-block ms-1">New Loan</span>

                            </IconButton>
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon="filter"
                                transform="shrink-3"
                                className="mx-2"
                            >
                                <span className="d-none d-sm-inline-block ms-1">Filter</span>
                            </IconButton>
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon="external-link-alt"
                                transform="shrink-3"
                            >
                                <span className="d-none d-sm-inline-block ms-1">Export</span>
                            </IconButton>
                        </div>
                    )}
                </Col>
            </Row>
        );
    };

    return (
        <>

            {
                error && <>
                    <div className="d-block bg-danger p-2 text-white mt-3">{error}</div>
                    <br />
                </>
            }

            <Card className="mb-3">
                <Card.Body>
                    <Row className="justify-content-between align-items-center">
                        <Col md>
                            <h5 className="mb-2 mb-md-0">My Loans</h5>
                        </Col>
                        <Col xs="auto">
                        </Col>
                        <Col xs="auto">
                            <h6 className="text-700 mb-0 text-nowrap">Status:</h6>
                        </Col>
                        <Col xs={2}>
                            <Form.Select
                                size="sm"
                                className="me-2"
                                onChange={e => setLoanStatus(e.target.value)}>
                                <option>Active</option>
                                <option>Closed</option>
                            </Form.Select>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            <AdvanceTableWrapper
                columns={columns}
                data={ExpenseData}
                selection
                sortable
                pagination
                perPage={10}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <ExpensesTableHeader table />
                    </Card.Header>
                    <Card.Body className="p-0">

                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination table />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>

            <AddExpense
                data={addDialogData}
                isDialogOpened={isAddExpenseDialogOpen}
                onCloseDialog={handleCloseAddMoneyAcountDialog}
            />

            <EditExpense
                data={editDialogData}
                isDialogOpened={isEditExpenseDialogOpen}
                onCloseDialog={handleCloseEditExpenseDialog}
            />

            <DeleteExpense
                data={deleteDialogData}
                isDialogOpened={isDeleteExpenseDialogOpen}
                onCloseDialog={handleCloseDeleteDialog}
            />
        </>

    );
};

export default Loans;
