import person2 from 'assets/img/team/2.jpg';
import person3 from 'assets/img/team/3.jpg';
import person4 from 'assets/img/team/4.jpg';
import person5 from 'assets/img/team/5.jpg';
import person6 from 'assets/img/team/6.jpg';
import person9 from 'assets/img/team/9.jpg';
import person13 from 'assets/img/team/13.jpg';
import person14 from 'assets/img/team/14.jpg';

export const categoriesData = [
    {
        name: 'Rent',
        group: 'Housing',
        type: 'Essential',
    },
    {
        name: 'Society Maintanance Fees',
        group: 'Housing',
        type: 'Essential',
    },
    {
        name: 'Maintanance & Oil Change',
        group: 'Transporation',
        type: 'Essential',
    },
    {
        name: 'Jwellary',
        group: 'Personal & Lifestyle',
        type: 'Non-Essential',
    },
    // {
    //     name: 'Rowen Atkinson',
    //     avatar: {
    //         name: 'Rowen Atkinson'
    //     },
    //     email: 'rown@example.com',
    //     phone: '(201) 200-1851',
    //     address: '112 Bostwick Avenue, Jersey City, New Jersey, 0730',
    //     joined: '05/04/2016'
    // },
    // {
    //     name: 'Antony Hopkins',
    //     avatar: {
    //         img: person2
    //     },
    //     email: 'antony@example.com',
    //     phone: '(901) 324-3127',
    //     address: '3448 Ile De France St #242, Fort Wainwright, Alaska, 99703',
    //     joined: '05/04/2018'
    // },
    // {
    //     name: 'Jennifer Schramm',
    //     avatar: {
    //         img: person3
    //     },
    //     email: 'jennifer@example.com',
    //     phone: '(828) 382-9631',
    //     address: '659 Hannah Street, Charlotte, NC 28273',
    //     joined: '17/03/2016'
    // },
    // {
    //     name: 'Raymond Mims',
    //     avatar: {
    //         name: 'Raymond Mims'
    //     },
    //     email: 'raymond@example.com',
    //     phone: '(562) 468-5646',
    //     address: '2298 Locust Court, Artesia, CA 90701',
    //     joined: '12/07/2014'
    // },
    // {
    //     name: 'Michael Jenkins',
    //     avatar: {
    //         img: person4
    //     },
    //     email: 'jenkins@example.com',
    //     phone: '(302) 613-8829',
    //     address: '4678 Maud Street, Philadelphia, DE 19103',
    //     joined: '15/06/2014'
    // },
    // {
    //     name: 'Kristine Cadena',
    //     avatar: {
    //         img: person13
    //     },
    //     email: 'cadena@example.com',
    //     phone: '(317) 273-7814',
    //     address: '3412 Crestview Manor, Indianapolis, IN 46234',
    //     joined: '15/04/2015'
    // },
    // {
    //     name: 'Suzanne Martinez',
    //     avatar: {
    //         name: 'Suzanne Martinez'
    //     },
    //     email: 'suzanne@example.com',
    //     phone: '(212) 344-9983',
    //     address: '4895 Farnum Road, New York, NY 10004',
    //     joined: '15/04/2016'
    // },
    // {
    //     name: 'Marie Cohen',
    //     avatar: {
    //         name: 'Marie Cohen'
    //     },
    //     email: 'cohen@example.com',
    //     phone: '(480) 610-3481',
    //     address: '3291 Hillside Street, Mesa, AZ 85201',
    //     joined: '25/08/2016'
    // },
    // {
    //     name: 'Michael Scates',
    //     avatar: {
    //         img: person9
    //     },
    //     email: 'scates@example.com',
    //     phone: '(323) 881-4878',
    //     address: '162 Hillhaven Drive, Los Angeles, CA 90063',
    //     joined: '20/12/2016'
    // },
    // {
    //     name: 'Kathryn Love',
    //     avatar: {
    //         img: person14
    //     },
    //     email: 'kathryn@example.com',
    //     phone: '(407) 446-3482',
    //     address: '2551 Ocala Street, Orlando, FL 32805',
    //     joined: '12/01/2015'
    // },
    // {
    //     name: 'Estell Pollich',
    //     avatar: {
    //         name: 'Estell Pollich'
    //     },
    //     email: 'estell@example.com',
    //     phone: '(201) 447-4782',
    //     joined: '23/04/2019',
    //     address: '13572 Kurt Mews South Merritt, IA 52491'
    // },
    // {
    //     name: 'Ara Mueller',
    //     avatar: {
    //         img: person2
    //     },
    //     email: 'ara@example.com',
    //     phone: '(202) 998-4702',
    //     joined: '23/04/2019',
    //     address: '91979 Kohler Place Waelchiborough, CT 41291'
    // },
    // {
    //     name: 'Lucienne Blick',
    //     avatar: {
    //         name: 'Lucienne Blick'
    //     },
    //     email: 'blick@example.com',
    //     phone: '(851) 265-4641',
    //     joined: '23/04/2019',
    //     address: '6757 Giuseppe Meadows Geraldinemouth, MO 48819-4970'
    // },
    // {
    //     name: 'Laverne Haag',
    //     avatar: {
    //         img: person5
    //     },
    //     email: 'haag@example.com',
    //     phone: '(245) 988-1927',
    //     joined: '22/04/2019',
    //     address: '2327 Kaylee Mill East Citlalli, AZ 89582-3143'
    // },
    // {
    //     name: 'Brandon Bednar',
    //     avatar: {
    //         img: person6
    //     },
    //     email: 'bednar@example.com',
    //     phone: '(953) 589-9324 ',
    //     joined: '22/04/2019',
    //     address: '25156 Isaac Crossing Apt. 810 Lonborough, CO 83774-5999'
    // },
    // {
    //     name: 'Dimitri Boehm',
    //     avatar: {
    //         name: 'Dimitri Boehm'
    //     },
    //     email: 'dimitri@example.com',
    //     phone: '(943) 409-9147',
    //     joined: '23/04/2019',
    //     address: '71603 Wolff Plains Apt. 885 Johnstonton, MI 01581'
    // },
    // {
    //     name: 'Trace Farrell',
    //     avatar: {
    //         name: 'Trace Farrell'
    //     },
    //     email: 'trace@example.com',
    //     phone: '(442) 485-0384',
    //     joined: '26/04/2019',
    //     address: '431 Steuber Mews Apt. 252 Germanland, AK 25882'
    // },
    // {
    //     name: 'Estell Nienow',
    //     avatar: {
    //         img: person13
    //     },
    //     email: 'nienow@example.com',
    //     phone: '(505) 697-7549',
    //     joined: '26/04/2019',
    //     address: '4167 Laverna Manor Marysemouth, NV 74590'
    // },
    // {
    //     name: 'Daisha Howe',
    //     avatar: {
    //         img: person14
    //     },
    //     email: 'howe@example.com',
    //     phone: '(337) 571-3547',
    //     joined: '25/04/2019',
    //     address: '829 Lavonne Valley Apt. 074 Stehrfort, RI 77914-0379'
    // },
    // {
    //     name: 'Miles Haley',
    //     avatar: {
    //         name: 'Miles Haley'
    //     },
    //     email: 'haley@example.com',
    //     phone: '(764) 619-4683',
    //     joined: '24/04/2019',
    //     address: '53150 Thad Squares Apt. 263 Archibaldfort, MO 00837'
    // },
    // {
    //     name: 'Brenda Watsica',
    //     avatar: {
    //         name: 'Brenda Watsica'
    //     },
    //     email: 'watsica@example.com',
    //     phone: '(961) 647-6123',
    //     joined: '24/04/2019',
    //     address: "9198 O'Kon Harbors Morarborough, IA 75409-7383"
    // },
    // {
    //     name: "Ellie O'Reilly",
    //     avatar: {
    //         name: "Ellie O'Reilly"
    //     },
    //     email: 'ellie@example.com',
    //     phone: ' (601) 688-8144',
    //     joined: '24/04/2019',
    //     address: '1478 Kaitlin Haven Apt. 061 Lake Muhammadmouth, SC 35848'
    // },
    // {
    //     name: 'Garry Brainstrow',
    //     avatar: {
    //         name: 'Garry Brainstrow'
    //     },
    //     email: 'garry@example.com',
    //     phone: '(401) 879-9970',
    //     joined: '23/04/2019',
    //     address: 'Garry Brainstrow, 13572 Kurt Mews South Merritt, IA 52491'
    // }
];
