import api from './api'

class UserService {

    getPublicContent() {
        return api.get("/test/all");
    }

    getUserBoard() {
        return api.get("/account/info")
    }

    getModeratorBoard() {
        return api.get("/test/mod");
    }

    getAdminBoard() {
        return api.get("/test/admin");
    }

    getExpenseGroup() {
        return api.get("/ExpenseGroup/getall");
    }

    getExpenseGroupById(id) {
        return api.get("/ExpenseGroup/get", {
            params: {
                id: id
            }
        });
    }

    createExpenseGroup(groupName, description) {
        return api.post("/ExpenseGroup/create", {
            groupName,
            description,
        })
    }

    updateExpenseGroup(id, groupName, description) {
        return api.post("/ExpenseGroup/update", {
            id,
            groupName,
            description,
        })
    }

    deleteExpenseGroup(id) {
        return api.post("/ExpenseGroup/delete", {
            id
        })
    }


    // Expense Category

    getExpenseCategories() {
        return api.get("/ExpenseCategory/getall");
    }

    getExpenseCategoryById(id) {
        return api.get("/ExpenseCategory/get", {
            params: {
                id: id
            }
        });
    }

    createExpenseCategory(categoryName, description, expenseGroupId) {
        return api.post("/ExpenseCategory/create", {
            categoryName,
            description,
            expenseGroupId
        })
    }

    updateExpenseCategory(id, categoryName, description, expenseGroupId) {
        return api.post("/ExpenseCategory/update", {
            id,
            categoryName,
            description,
            expenseGroupId
        })
    }

    deleteExpenseCategory(id) {
        return api.post("/ExpenseCategory/delete", {
            id
        })
    }




}

export default new UserService();