import { month } from "is_js";
import bg1 from 'assets/img/icons/spot-illustrations/corner-1.png';
import bg2 from 'assets/img/icons/spot-illustrations/corner-2.png';
import bg3 from 'assets/img/icons/spot-illustrations/corner-3.png';
import email from 'assets/img/icons/email.svg';
import social from 'assets/img/icons/social.svg';
import call from 'assets/img/icons/call.svg';
import other from 'assets/img/icons/other.svg';

export const totalLoans = [
    [60, 80, 60, 80, 65, 130, 120, 100, 30, 40, 30, 70],
    [100, 70, 80, 50, 120, 100, 130, 140, 90, 100, 40, 50],
    [80, 50, 60, 40, 60, 120, 100, 130, 60, 80, 50, 60],
    [70, 80, 100, 70, 90, 60, 80, 130, 40, 60, 50, 80],
    [90, 40, 80, 80, 100, 140, 100, 130, 90, 60, 70, 50],
    [80, 60, 80, 60, 40, 100, 120, 100, 30, 40, 30, 70],
    [20, 40, 20, 50, 70, 60, 110, 80, 90, 30, 50, 50],
    [60, 70, 30, 40, 80, 140, 80, 140, 120, 130, 100, 110],
    [90, 90, 40, 60, 40, 110, 90, 110, 60, 80, 60, 70],
    [50, 80, 50, 80, 50, 80, 120, 80, 50, 120, 110, 110],
    [60, 90, 60, 70, 40, 70, 100, 140, 30, 40, 30, 70],
    [20, 40, 20, 50, 30, 80, 120, 100, 30, 40, 30, 70]
];

const loanData = [
    [0, 80, 60, 80, 65, 130, 120, 100, 30, 40, 30, 70],
    [100, 70, 80, 50, 120, 100, 130, 140, 90, 100, 40, 50],
    [80, 50, 60, 40, 60, 120, 100, 130, 60, 80, 50, 60],
    [70, 80, 100, 70, 90, 60, 80, 130, 40, 60, 50, 80],
    [90, 40, 80, 80, 100, 140, 100, 130, 90, 60, 70, 50],
    [80, 60, 80, 60, 40, 100, 120, 100, 30, 40, 30, 70],
    [20, 40, 20, 50, 70, 60, 110, 80, 90, 30, 50, 50],
    [60, 70, 30, 40, 80, 140, 80, 140, 120, 130, 100, 110],
    [90, 90, 40, 60, 40, 110, 90, 110, 60, 80, 60, 70],
    [50, 80, 50, 80, 50, 80, 120, 80, 50, 120, 110, 110],
    [60, 90, 60, 70, 40, 70, 100, 140, 30, 40, 30, 70],
    [20, 40, 20, 50, 30, 80, 120, 100, 30, 40, 30, 70]
];

const loanData2 = {
    2021: [970000, 940000, 910000, 880000, 850000, 820000, 790000, 760000, 730000, 700000, 670000, 640000],
    // 2023: [80, 50, 60, 40, 60, 120, 100, 130, 60, 80, 50, 60],
    2022: [610000, 580000, 598000, 568000, 538000, 508000, 478000, 448000, 418000, 388000, 358000, 328000],

    //0: [70, 80, 100, 70, 90, 60, 80, 130, 40, 60, 50, 80],
    // [90, 40, 80, 80, 100, 140, 100, 130, 90, 60, 70, 50],
    // [80, 60, 80, 60, 40, 100, 120, 100, 30, 40, 30, 70],
    // [20, 40, 20, 50, 70, 60, 110, 80, 90, 30, 50, 50],
    // [60, 70, 30, 40, 80, 140, 80, 140, 120, 130, 100, 110],
    // [90, 90, 40, 60, 40, 110, 90, 110, 60, 80, 60, 70],
    // [50, 80, 50, 80, 50, 80, 120, 80, 50, 120, 110, 110],
    // [60, 90, 60, 70, 40, 70, 100, 140, 30, 40, 30, 70],
    // [20, 40, 20, 50, 30, 80, 120, 100, 30, 40, 30, 70]
};

const titles = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"
]

const months = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"
]


export const newJsonData = () => {


    const years = [];
    const titles = [];
    const datas = [];

    var loanOpeningBalance = 1200000;

    for (let year = 2016; year < 2023; year++) {
        years.push(year);

        for (let m = 0; m < 12; m++) {

            var month = months[m];

            if (year == 2020 && month == "Jan") {
                loanOpeningBalance += 650000;
            }

            if (year == 2022 && month == "Mar") {
                loanOpeningBalance += 48000;
            }

            loanOpeningBalance = loanOpeningBalance - 14000;

            var title = `${month} ${year.toString().substring(2, 4)}`;

            titles.push(title);
            datas.push(loanOpeningBalance);
        }
    }
    var jsonData = {
        title: titles,
        data: datas,
        years: years
    }

    return jsonData;



}

function getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
}


export const monthlyExpensesData = () => {

    const titles = [];
    const datas = [];


    var endDate = new Date(endDate);

    var fromDate = new Date(2023, 1, 1);
    var toDate = new Date(2023, 8, 1);
    var date = fromDate;
    var monthNameList = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];


    while (date <= toDate) {

        var year = date.getFullYear();
        var month = date.getMonth();

        var monthYear = monthNameList[month] + " " + year;
        var monthExpenses = getRandomIntInclusive(6000, 24000);

        titles.push(monthYear);
        datas.push(monthExpenses);

        //console.log(date)
        date.setMonth(date.getMonth() + 1)
    }

    // for (var day = from; day <= to; day.setMonth(day.getMonth() + 1)) {

    //     var year = day.getFullYear();
    //     var month = day.getMonth();

    //     var monthYear = monthNameList[month] + " " + year;
    //     var monthExpenses = getRandomIntInclusive(6000, 24000);

    //     titles.push(monthYear);
    //     datas.push(monthExpenses);

    //     console.log(day)
    // }

    var jsonData = {
        title: titles,
        data: datas,
    }

    console.log("chart data", jsonData);
    return jsonData;
}




// function draw() {
//     ctx.clearRect(0, 0, WIDTH, HEIGHT);
//     for (let i = 0; i < 100; i++) {
//         ctx.beginPath();
//         ctx.fillStyle = "rgba(255,0,0,0.5)";
//         ctx.arc(random(WIDTH), random(HEIGHT), random(50), 0, 2 * Math.PI);
//         ctx.fill();
//     }
// }

export const newLoanData = {
    title: titles,
    data: loanData2,
}

export const statsData = [
    {
        title: 'Total Expenses',
        value: 58.39,
        decimal: true,
        suffix: 'k',
        prefix: '',
        valueClassName: 'text-warning',
        badgeBg: 'warning',
        badgeText: '12.5%',
        link: '/e-commerce/customers',
        linkText: 'See all',
        image: bg1
    },
    {
        title: 'Total Transactions',
        value: 122,
        decimal: false,
        suffix: '',
        prefix: '',
        valueClassName: 'text-info',
        badgeBg: 'info',
        badgeText: '5%',
        link: '/e-commerce/orders/order-list',
        linkText: 'All orders',
        image: bg2
    },
    {
        title: 'Revenue',
        value: 43594,
        decimal: false,
        suffix: '',
        prefix: '$',
        valueClassName: '',
        badgeBg: 'success',
        badgeText: '9.54%',
        link: '/',
        linkText: 'Statistics',
        image: bg3
    }
];

export const leadsData = [
    {
        id: 1,
        title: 'Groceries',
        img: email,
        amount: '5,424'
    },
    {
        id: 2,
        title: 'Clothing & Shoes',
        img: social,
        amount: '5,042'
    },
    {
        id: 3,
        title: 'Restaurant',
        img: call,
        amount: '2,116'
    },
    {
        id: 4,
        title: 'Fuel',
        img: other,
        amount: '1,320'
    },
    {
        id: 5,
        title: 'Transport',
        img: other,
        amount: '1,047'
    },
    {
        id: 6,
        title: 'Healthcare',
        img: other,
        amount: '650'
    }

];

export const dealForecastData = [
    {
        id: 1,
        title: 'ICIC',
        amount: 43230,
        variant: 'progress-gradient'
    },
    {
        id: 2,
        title: 'Contact sent',
        amount: 18268,
        variant: 'soft-primary'
    },
    {
        id: 3,
        title: 'Pending',
        amount: 16279,
        variant: 'soft-info'
    },
    {
        id: 4,
        title: 'Qualified',
        amount: 12661,
        variant: 'info'
    }
];
