// https://fontawesome.com/search?q=bills&o=r&m=free
// https://react-icons.github.io/react-icons/search?q=notes


export const mainRoute = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      icon: 'chart-pie',
      to: '/default',
      active: true,
      children: [
        // {
        //   name: 'Default',
        //   to: '/default',
        //   active: true
        // },
        {
          name: 'Default',
          to: '/default',
          active: true
        },
        {
          name: 'Day',
          to: '/daywise',
          active: true
        }
      ]
    },
    // {
    //   name: 'Daywise',
    //   icon: 'chart-line',
    //   to: '/daywise',
    //   active: true
    // },
    // {
    //   name: 'Transactions',
    //   icon: 'wallet',
    //   to: '/transactions',
    //   active: true
    // },
    {
      name: 'Expenses',
      icon: 'wallet',
      to: '/expenses',
      active: true
    },
    {
      name: 'Money Plan',
      icon: 'briefcase',
      to: '/money-plan',
      active: true
    },
    // {
    //   name: 'Bills',
    //   icon: 'money-bill',
    //   to: '/bills',
    //   active: true
    // },
    // {
    //   name: 'Masters',
    //   icon: 'envelope-open',
    //   active: true,
    //   children: [
    //     {
    //       name: 'Expenes Categories',
    //       to: '/categories',
    //       active: true
    //     },
    //     {
    //       name: 'Accounts',
    //       to: '/email/email-detail',
    //       active: true
    //     }
    //   ]
    // },
  ]
}

export const accountsRoute = {
  label: 'Masters',
  labelDisable: false,
  children: [
    {
      name: 'Categories',
      icon: 'clipboard',
      to: '/categories',
      active: true
    },
    {
      name: 'Groups',
      icon: 'layer-group',
      to: '/expense-group',
      active: true
    },
    {
      name: 'Money Accounts',
      icon: 'money-bill',
      to: '/money-accounts',
      active: true
    },
    {
      name: 'Loans',
      icon: 'hand-holding-usd',
      to: '/loans',
      active: true
    },
    {
      name: 'Credit Cards',
      icon: 'credit-card',
      to: '/credit-cards',
      active: true
    },

    {
      name: 'Subscriptions',
      icon: 'calendar-alt',
      to: '/cash-bank',
      active: true
    },
    // {
    //   name: 'Masters',
    //   icon: 'envelope-open',
    //   active: true,
    //   children: [
    //     {
    //       name: 'Expenes Categories',
    //       to: '/categories',
    //       active: true
    //     },
    //     {
    //       name: 'Accounts',
    //       to: '/email/email-detail',
    //       active: true
    //     }
    //   ]
    // },
  ]
}

export default [
  mainRoute,
  accountsRoute
];
