import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import {
  Card,
  Modal,
  Nav,
  OverlayTrigger,
  Tooltip,
  Dropdown,
  Button
} from 'react-bootstrap';
// import Login from 'components/authentication/simple/Login';
// import Registration from 'components/authentication/simple/Registration';
import AppContext from 'context/Context';

const LandingRightSideNavItem = () => {
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);

  return (
    <Nav navbar className="ms-auto">
      {/* <Nav.Item as={'li'}>
        <Nav.Link
          className="pe-2 theme-switch-toggle"
          onClick={() => setConfig('isDark', !isDark)}
        >
          <OverlayTrigger
            key="left"
            placement={isRTL ? 'bottom' : 'left'}
            overlay={
              <Tooltip
                style={{ position: 'fixed' }}
                id="ThemeColor"
                className="d-none d-lg-block"
              >
                {isDark ? 'Switch to light theme' : 'Switch to dark theme'}
              </Tooltip>
            }
          >
            <span>
              <div className="theme-switch-toggle-label">
                <FontAwesomeIcon
                  icon={isDark ? 'sun' : 'moon'}
                  className="fs-0"
                />
              </div>
              <p className="d-lg-none mb-0">
                {isDark ? 'Switch to light theme ' : 'Switch to dark theme'}
              </p>
            </span>
          </OverlayTrigger>
        </Nav.Link>
      </Nav.Item> */}

      {/* <Nav.Item>
        <Link to={"#!"} className="nav-link fw-normal text-black text-800">Home</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to={"#!"} className="nav-link fw-normal text-black text-800">Features</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to={"#!"} className="nav-link fw-normal text-black  text-800">Contact</Link>
      </Nav.Item> */}
      <Nav.Item>
        {/* <Link to={"login"} className="nav-link fw-normal text-black fs-0 text-800">Login</Link> */}
        <Link to={"login"}>
          <Button variant="outline-primary" className="rounded-pill me-2 mb-1" size="sm">
            Login
          </Button>
        </Link>
      </Nav.Item>
      <Nav.Item>
        <Link to={"#!"}>
          <Button variant="outline-warning" className="rounded-pill me-2 mb-1" size="sm">
            Register Now
          </Button>
        </Link>
      </Nav.Item>
    </Nav>
  );
};

export default LandingRightSideNavItem;
