import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import 'react-datepicker/dist/react-datepicker.css';
import PageHeader from './components/common/PageHeader';
import Layout from './layouts/Layout'
import Login from 'components/Login/Login';
import PrivateRoute from './routes/PrivateRoute'
import { useUser } from 'hooks/useUser';
import { AuthProvider } from 'context/Auth'
import { registerLicense } from '@syncfusion/ej2-base';

registerLicense('Ngo9BigBOggjHTQxAR8/V1NCaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXdfcHVWQmhdU0Z2XUY=');

const App = () => {


    //const { authenticated } = useUser();

    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {

        setAuthenticated(true);

    }, []);

    return (
        <AuthProvider>
            <Router basename={process.env.PUBLIC_URL} >

                {/* <Routes>
                <Route path="/login" element={<Login />} />
            </Routes> */}

                < Layout isAuthenticated={authenticated} />

                {/* <Navigate from="/" to="/login" /> */}

            </Router >
        </AuthProvider>

    );
};

export default App;
