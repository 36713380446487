class TokenService {

    setUser(user) {
        var userStr = JSON.stringify(user);
        localStorage.setItem("USER-KEY", userStr);
    }

    getUser() {
        var userRaw = localStorage.getItem("USER-KEY");
        var user = JSON.parse(userRaw);
        return user;
    }

    removeUser() {
        localStorage.removeItem("USER-KEY");
    }

    getLocalRefreshToken() {
        const user = this.getUser();
        return user?.refreshToken;
    }

    getLocalAccessToken() {
        const user = this.getUser();
        return user?.accessToken;
    }

    updateLocalAccessToken(accessToken, refreshToken) {
        let user = this.getUser();
        user.accessToken = accessToken;
        user.refreshToken = refreshToken;

        var userStr = JSON.stringify(user);
        localStorage.setItem("USER-KEY", userStr);
    }

}

export default new TokenService();