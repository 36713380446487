import api from './api'

class MoneyAccountService {


    // Money Accounts

    getMoneyAccounts() {
        return api.get("/MoneyAccount/getall");
    }

    getMoneyAccountById(id) {
        return api.get("/MoneyAccount/get", {
            params: {
                id: id
            }
        });
    }

    createMoneyAccount(accountName, category, details) {
        return api.post("/MoneyAccount/create", {
            accountName,
            category,
            details
        })
    }

    updateMoneyAccount(id, accountName, category, details) {
        return api.post("/MoneyAccount/update", {
            id,
            accountName,
            category,
            details
        })
    }

    deleteMoneyAccount(id) {
        return api.post("/MoneyAccount/delete", {
            id
        })
    }


}

export default new MoneyAccountService();