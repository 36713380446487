import React, { useState, useEffect } from 'react';
import { Card, Dropdown, Row, Col, Form, Button } from 'react-bootstrap';
import { categoriesData } from 'data/expensesCategoriesData';
import CardDropdown from 'components/common/CardDropdown';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import ExpenseGroupHeader from './ExpenseGroupHeader';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import UserService from 'services/userService';
import AddExpenseGroup from './AddExpenseGroup';
import EditExpenseGroup from './EditExpenseGroup';
import DeleteExpenseGroup from './DeleteExpenseGroup';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import IconButton from 'components/common/IconButton';


const ExpenseGroup = () => {

    const [groups, setGroups] = useState([]);
    const [error, setError] = useState();

    const [addDialogData, setAddDialogData] = useState({ id: 0, groupName: '', description: '' });
    const [isAddGroupDialogOpen, setIsOpenAddGroupDialog] = useState(false);

    const [editDialogData, setEditDialogData] = useState({ id: 0, groupName: '', description: '' });
    const [isEditGroupDialogOpen, setIsOpenEditGroupDialog] = useState(false);

    const [deleteDialogData, setDeleteDialogData] = useState({ id: 0, groupName: '', command: '' });
    const [isDeleteCategoryDialogOpen, setIsOpenDeleteCategoryDialog] = useState(false);


    // const [isOpenAddExpenseGroupModal, setIsOpenAddExpenseGroupModal] = useState(false);
    // const [isOpenEditExpenseGroupModal, setIsOpenEditExpenseGroupModal] = useState(false);
    // const [editableData, setEditableData] = useState('');

    // const [formLoaded, setFormLoaded] = useState(false);
    // const [dataSubmitted, setDataSubmitted] = useState('');

    // const [deleteDialog, setDeleteDialog] = useState({ show: false, id: 0, data: '' });

    // const [deleteDialogData, setDeleteDialogData] = useState({ id: 0, groupName: '', command: '' });

    // const [isDeleteGroupDialogOpen, setIsOpenDeleteGroupDialog] = useState(false);

    useEffect(() => {

        loadData();

    }, []);

    function loadData() {
        try {

            UserService.getExpenseGroup().then(
                response => {
                    //console.log("expense groups received");
                    //console.log(response);
                    if (response.data) {
                        setGroups(response.data);
                    }
                },
                error => {

                }
            )
        } catch (err) {

        } finally {
            //setFormLoaded(true);
        }
    }

    const openAddCategoryDialog = () => {

        setAddDialogData({
            id: 0, groupName: '', description: ''
        })

        setIsOpenAddGroupDialog(true);

    }

    function handleCloseAddCategoryDialog(isSubmitted) {
        setIsOpenAddGroupDialog(false);

        if (isSubmitted) {
            loadData();
        }
    }


    const openGroupEditModel = (id) => {

        try {

            UserService.getExpenseGroupById(id).then(
                response => {
                    setEditDialogData(response.data);
                },
                error => {

                }
            )
        } catch (err) {
        } finally {
        }

        setIsOpenEditGroupDialog(true);
    }

    function handleCloseEditCategoryDialog(isSubmitted) {
        setIsOpenEditGroupDialog(false);

        if (isSubmitted) {
            loadData();
        }
    }

    const openDeleteDialog = (id, groupName) => {

        setDeleteDialogData({
            id: id,
            groupName: groupName,
            command: ''
        })

        setIsOpenDeleteCategoryDialog(true);

    }

    function handleCloseDeleteDialog(isSubmitted) {
        setIsOpenDeleteCategoryDialog(false);

        if (isSubmitted) {
            loadData();
        }
    }


    const columns = [
        // {
        //     accessor: 'id',
        //     Header: 'Id'
        // },
        {
            accessor: 'groupName',
            Header: 'Name',
            Cell: rowData => {
                const { groupName } = rowData.row.original;
                return <h5 className="mb-0 fs--1">{groupName}</h5>
            }
        },
        {
            accessor: 'description',
            Header: 'Description'
        },
        {
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            cellProps: {
                className: 'text-end'
            },
            Cell: rowData => {
                const { id, groupName } = rowData.row.original;
                return (
                    <CardDropdown iconClassName="fs--1">
                        <div className="py-2">
                            <Dropdown.Item onClick={() => {
                                openGroupEditModel(id);
                            }}>Edit</Dropdown.Item>

                            <Dropdown.Item onClick={() => {
                                openDeleteDialog(id, groupName);
                            }}>Delete</Dropdown.Item>
                        </div>
                    </CardDropdown>
                );
            }
        }
    ];



    // useEffect(() => {
    //     //console.log(`data submitted`);
    //     //if (dataSubmitted) {

    //     try {

    //         UserService.getExpenseGroup().then(
    //             response => {
    //                 //console.log("expense groups received");
    //                 //console.log(response);
    //                 if (response.data) {
    //                     setGroups(response.data);
    //                 }
    //             },
    //             error => {

    //             }
    //         )
    //     } catch (err) {

    //     } finally {

    //     }
    //     //}
    // }, [dataSubmitted])

    const GroupTableHeader = ({
        selectedRowIds,
        globalFilter,
        setGlobalFilter
    }) => {
        return (
            <Row className="flex-between-center">
                {/* <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                    <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Categories</h5>
                </Col> */}
                <Col xs={4} >
                    <AdvanceTableSearchBox
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                    />
                </Col>
                <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                    {Object.keys(selectedRowIds).length > 0 ? (
                        <div className="d-flex">
                            <Form.Select size="sm" aria-label="Bulk actions">
                                <option>Bulk Actions</option>
                                <option value="refund">Refund</option>
                                <option value="delete">Delete</option>
                                <option value="archive">Archive</option>
                            </Form.Select>
                            <Button
                                type="button"
                                variant="falcon-default"
                                size="sm"
                                className="ms-2"
                            >
                                Apply
                            </Button>
                        </div>
                    ) : (
                        <div id="orders-actions">
                            <IconButton
                                variant="falcon-primary"
                                size="sm"
                                icon="plus"
                                transform="shrink-3"
                                onClick={() => {
                                    openAddCategoryDialog();
                                }}
                            >
                                <span className="d-none d-sm-inline-block ms-1">New Group</span>

                            </IconButton>
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon="filter"
                                transform="shrink-3"
                                className="mx-2"
                            >
                                <span className="d-none d-sm-inline-block ms-1">Filter</span>
                            </IconButton>
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon="external-link-alt"
                                transform="shrink-3"
                            >
                                <span className="d-none d-sm-inline-block ms-1">Export</span>
                            </IconButton>
                        </div>
                    )}
                </Col>
            </Row>
        );
    };

    return (
        <>

            <Card className="mb-3">
                <Card.Body>
                    <Row className="justify-content-between align-items-center">
                        <Col md>
                            <h5 className="mb-2 mb-md-0">Expense  Groups</h5>
                        </Col>
                        <Col xs="auto">
                        </Col>

                    </Row>
                </Card.Body>
            </Card>
            <AdvanceTableWrapper
                columns={columns}
                data={groups}
                selection
                sortable
                pagination
                perPage={10}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <GroupTableHeader table />
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination table />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>

            <AddExpenseGroup
                data={addDialogData}
                isDialogOpened={isAddGroupDialogOpen}
                onCloseDialog={handleCloseAddCategoryDialog}
            />

            <EditExpenseGroup
                data={editDialogData}
                isDialogOpened={isEditGroupDialogOpen}
                onCloseDialog={handleCloseEditCategoryDialog}
            />

            <DeleteExpenseGroup
                data={deleteDialogData}
                isDialogOpened={isDeleteCategoryDialogOpen}
                onCloseDialog={handleCloseDeleteDialog}
            />
        </>
    );
};

export default ExpenseGroup;
