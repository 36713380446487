import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Form } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import AppContext from 'context/Context';
import DatePicker from 'react-datepicker';
import { Formik, Field, Form as FormikForm } from 'formik';
import { FormTextField, FormTextAreaField } from 'helpers/bootstrap-formlk';
import UserService from 'services/userService';

//https://codesandbox.io/s/react-bootstrap-formik-pb831?file=/src/form-field.js


const EditExpenseGroup = ({
    data,
    isDialogOpened,
    onCloseDialog
}) => {
    const {
        config: { isDark }
    } = useContext(AppContext);

    const INITIAL_VALUES = { id: 0, groupName: '', description: '' };

    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState(null);

    function defaultState() {
        setError('');
    }

    useEffect(() => {
        defaultState();

        if (data) {
            setFormData(data);
        }

    }, [data]);

    function handleClose(isSubmitted) {
        onCloseDialog(isSubmitted);
    }

    // useEffect(() => {
    //     if (isOpenScheduleModal) {
    //         setFormData({
    //             ...formData,
    //             start: scheduleStartDate,
    //             end: scheduleEndDate
    //         });
    //     } else {
    //         setScheduleStartDate(null);
    //         setScheduleEndDate(null);
    //     }
    // }, [isOpenScheduleModal, scheduleStartDate, scheduleEndDate]);

    const submit = async (values) => {

        try {
            setIsLoading(true);

            UserService.updateExpenseGroup(values.id, values.groupName, values.description).then(
                response => {
                    handleClose(true);
                },
                error => {
                    setError(error);
                }
            )
        } catch (err) {
            setError(err);
        } finally {
            setIsLoading(false);
        }

    };

    return (
        <Modal
            show={isDialogOpened}
            onHide={e => handleClose(false)}
            contentClassName="border"
            backdrop="static"
            centered
        >
            <Formik
                initialValues={data}
                enableReinitialize={true}
                onSubmit={submit} >

                <FormikForm >
                    <Modal.Header
                        closeButton
                        closeVariant={isDark ? 'white' : undefined}
                        className="bg-light px-x1 border-bottom-0"
                    >
                        <Modal.Title as="h5"> Update Expenses Group</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-x1">
                        <Form.Group className="mb-3" controlId="formGroupName">
                            <Form.Label className="fs-0">Group Name</Form.Label>
                            <FormTextField name="groupName" type="text" />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formDescription">
                            <Form.Label className="fs-0">Description</Form.Label>
                            <FormTextAreaField name="description" rows={3} />
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer className="bg-light px-x1 border-top-0">

                        <Button
                            variant="default"
                            type="button"
                            onClick={handleClose}
                            className="px-4 mx-0"
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            type="submit"
                            //onClick={handleClose}
                            className="px-4 mx-0 ms-2"
                        >
                            Save
                        </Button>
                    </Modal.Footer>
                </FormikForm>
            </Formik>
        </Modal>
    );
};

EditExpenseGroup.propTypes = {
    setIsOpenEditExpenseGroupModal: PropTypes.func.isRequired,
    isOpenEditExpenseGroupModal: PropTypes.bool.isRequired,
    setDataSubmitted: PropTypes.func.isRequired,
    setInitialEvents: PropTypes.func,
    initialEvents: PropTypes.array,
};

export default EditExpenseGroup;
