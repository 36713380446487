import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import TotalLoans from 'components/dashboards/loans/TotalLoans'
import MonthExpenses from './MonthyExepenses';
import { totalLoans, newLoanData, newJsonData, monthlyExpensesData, statsData, dealForecastData } from 'data/dashboard/default'
import StatisticsCard from '../StatisticsCard';
import CategoryWiseChart from './CategoryWise'
import PayeeBar from './PayeeBar';
import UserService from 'services/userService';
import DashboardService from 'services/DashboardService';
import { error } from 'is_js';
import bg1 from 'assets/img/icons/spot-illustrations/corner-1.png';
import bg2 from 'assets/img/icons/spot-illustrations/corner-2.png';
import bg3 from 'assets/img/icons/spot-illustrations/corner-3.png';

const Dashboard = () => {

    var loanData = newJsonData();
    var expensesData = newJsonData();

    const [userName, setUserName] = useState("");
    const [dashboardData, setDashboardData] = useState({ totalExpenses: 0, totalTransactions: 0 });
    const [monthWiseExpenses, setMonthWiseExpenses] = useState({ title: [], data: [] });
    const [expenseByAccount, setExpenseByAccount] = useState([]);

    useEffect(() => {

        try {

            DashboardService.getDashboard1().then(
                response => {
                    console.log("response received", response.data);
                    if (response.data) {
                        var data = response.data;
                        setDashboardData(data);
                        setMonthWiseExpenses(data.monthWiseExpensesData);
                        setExpenseByAccount(data.expensesByAccount);

                    }
                },
                error => {

                }
            )
        } catch (err) {

        } finally {

        }

    }, []);

    return (
        <>
            {/* <h2>{userName}</h2> */}
            <Row className='g-3 mb-3'>
                {/* <Col lg={12}>
                    <PageHeader
                        title="FAQ Accordion"
                        description="Below you'll find answers to the questions we get asked the most about to join with Falcon"
                        className="mb-3"
                    />
                </Col> */}
                <Col lg={12}>
                    <Row className="g-3 h-100">
                        {/* {statsData.slice(0, 2).map(stat => (
                            <Col key={stat.title} sm={6}>
                                <StatisticsCard stat={stat} style={{ minWidth: '12rem' }} />
                            </Col>
                        ))} */}
                        <Col key="totalExpenses" sm={6}>
                            <StatisticsCard stat={{
                                title: 'Total Expenses',
                                value: dashboardData.totalExpenses,
                                decimal: true,
                                suffix: 'k',
                                prefix: '',
                                valueClassName: 'text-warning',
                                badgeBg: 'warning',
                                badgeText: '12.5%',
                                link: '/e-commerce/customers',
                                linkText: 'See all',
                                image: bg1
                            }} style={{ minWidth: '12rem' }} />
                        </Col>
                        <Col key="totalTransactions" sm={6}>
                            <StatisticsCard stat={
                                {
                                    title: 'Total Transactions',
                                    value: dashboardData.totalTransactions,
                                    decimal: false,
                                    suffix: '',
                                    prefix: '',
                                    valueClassName: 'text-info',
                                    badgeBg: 'info',
                                    badgeText: '5%',
                                    link: '/e-commerce/orders/order-list',
                                    linkText: 'All orders',
                                    image: bg2
                                }
                            } style={{ minWidth: '12rem' }} />
                        </Col>

                    </Row>
                </Col>
                {/* <Col lg={6}>
                    <CategoryWiseChart />
                </Col> */}

                <Col sm={12}>
                    <MonthExpenses data={monthWiseExpenses} />
                </Col>
            </Row>

            <Row className='g-3 mb-3'>
                <Col sm={12}>
                    <PayeeBar data={expenseByAccount} />
                </Col>

            </Row>
        </>
    )
}

export default Dashboard;
