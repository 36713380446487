import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Container, Nav, Navbar } from 'react-bootstrap';
import handleNavbarTransparency from 'helpers/handleNavbarTransparency';
//import NavbarTopDropDownMenus from 'components/navbar/top/NavbarTopDropDownMenus';
import LandingRightSideNavItem from './LandingRightSideNavItem';
import { topNavbarBreakpoint } from 'config';
import AppContext from 'context/Context';
import homelogo from "assets/img/illustrations/falcon.png"


const NavbarStandard = () => {
  // const {
  //   config: { isDark }
  // } = useContext(AppContext);

  const isDark = false;

  const [navbarCollapsed, setNavbarCollapsed] = useState(true);

  useEffect(() => {
    window.addEventListener('scroll', handleNavbarTransparency);
    return () => window.removeEventListener('scroll', handleNavbarTransparency);
  }, []);


  return (
    <Navbar
      variant={!isDark ? 'light' : 'dark'}
      fixed="top"
      expand={topNavbarBreakpoint}
      className={classNames('navbar-standard navbar-theme', {
        'bg-100': !navbarCollapsed && isDark,
        'bg-dark': !navbarCollapsed && !isDark
      })}
    >
      <Container>
        <Navbar.Brand className=" dark__text-white" as={Link} to="/">
          Spenzzy<span
            className="text-warning">.</span>
        </Navbar.Brand>
        <Navbar.Toggle onClick={() => setNavbarCollapsed(!navbarCollapsed)} />
        <Navbar.Collapse className="scrollbar">
          <Nav>
            {/* <NavbarTopDropDownMenus setNavbarCollapsed={setNavbarCollapsed} /> */}
            {/* <Nav.Item>
              <Link to={"#!"} className="nav-link fw-normal text-black text-800">Home</Link>
            </Nav.Item>
            <Nav.Item>
              <Link to={"#!"} className="nav-link fw-normal text-black text-800">Features</Link>
            </Nav.Item>
            <Nav.Item>
              <Link to={"#!"} className="nav-link fw-normal text-black  text-800">Contact</Link>
            </Nav.Item> */}

            <NavLink to="#" className='nav-link' >Home
            </NavLink>

            <NavLink to="#" className={'nav-link'}>Features
            </NavLink>

            <NavLink to="#" className={'nav-link'}>Contact
            </NavLink>

          </Nav>
          <LandingRightSideNavItem />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarStandard;
