import React, { useState, useEffect } from 'react';
import { Card, Dropdown, Col, Form, Row, Button } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import CardDropdown from 'components/common/CardDropdown';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import MoneyAccountService from 'services/MoneyAccountService';
import AddMoneyAccount from './AddMoneyAccount';
import EditMoneyAccount from './EditMoneyAccount'
import DeleteMoneyAccount from './DeleteMoneyAccount';



const MoneyAccounts = () => {

    const [moneyAccountData, setMoneyAccountData] = useState([]);
    const [error, setError] = useState();

    const [addDialogData, setAddDialogData] = useState({ id: 0, accountName: '', category: '', details: '' });
    const [isAddMoneyAccountDialogOpen, setIsOpenAddMoneyAccountDialog] = useState(false);

    const [editDialogData, setEditDialogData] = useState({ id: 0, accountName: '', category: '', details: '' });
    const [isEditMoneyAccountDialogOpen, setIsOpenMoneyAccountDialog] = useState(false);

    const [deleteDialogData, setDeleteDialogData] = useState({ id: 0, categoryName: '', command: '' });
    const [isDeleteMoneyAccountDialogOpen, setIsOpenDeleteMoneyAccountDialog] = useState(false);

    useEffect(() => {

        loadData();

    }, []);

    function loadData() {
        try {

            MoneyAccountService.getMoneyAccounts().then(
                response => {

                    if (response.data) {
                        setMoneyAccountData(response.data);
                    }
                },
                error => {
                    setError(error);
                }
            )
        } catch (err) {
            setError(err);
        } finally {
            //setFormLoaded(true);
        }
    }

    const openAddMoneyAccountDialog = () => {

        setAddDialogData({
            id: 0,
            accountName: '',
            category: '',
            details: '',
        })

        setIsOpenAddMoneyAccountDialog(true);

    }

    function handleCloseAddMoneyAcountDialog(isSubmitted) {
        setIsOpenAddMoneyAccountDialog(false);

        if (isSubmitted) {
            loadData();
        }
    }

    const openEditMoneyAccountDialog = (id) => {

        try {

            MoneyAccountService.getMoneyAccountById(id).then(
                response => {
                    //console.log("expense group received " + id);
                    console.log("openEditMoneyAccountDialog", response.data);
                    setEditDialogData(response.data);

                    // if (response.data) {
                    //     setGroups(response.data);
                    // }
                },
                error => {

                }
            )
        } catch (err) {
        } finally {

        }

        setIsOpenMoneyAccountDialog(true);

    }

    function handleCloseEditMoneyAccountDialog(isSubmitted) {
        setIsOpenMoneyAccountDialog(false);

        if (isSubmitted) {
            loadData();
        }
    }

    const openDeleteMoneyAccountDialog = (id, accountName) => {


        setDeleteDialogData({
            id: id,
            accountName: accountName,
            command: ''
        })

        setIsOpenDeleteMoneyAccountDialog(true);

    }

    function handleCloseDeleteDialog(isSubmitted) {
        setIsOpenDeleteMoneyAccountDialog(false);

        if (isSubmitted) {
            loadData();
        }
    }



    const columns = [
        {
            accessor: 'accountName',
            Header: 'Name',
            Cell: rowData => {
                const { accountName } = rowData.row.original;
                return <h5 className="mb-0 fs--1">{accountName}</h5>
            }
        },
        {
            accessor: 'categoryName',
            Header: 'Category',
            headerProps: { style: { minWidth: '200px' }, className: 'ps-5' },
            cellProps: { className: 'ps-5' }
        },
        {
            accessor: 'details',
            Header: 'Description'
        },
        {
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            cellProps: {
                className: 'text-end'
            },
            Cell: (rowData) => {
                const { id, accountName } = rowData.row.original;

                return (
                    <CardDropdown iconClassName="fs--1">
                        <div className="py-2">
                            <Dropdown.Item onClick={() => {
                                openEditMoneyAccountDialog(id);
                            }}>Edit</Dropdown.Item>

                            <Dropdown.Item onClick={() => {
                                openDeleteMoneyAccountDialog(id, accountName);
                            }}>Delete</Dropdown.Item>
                        </div>
                    </CardDropdown>
                );
            }
        }
    ];


    const MoneyAccountsTableHeader = ({ selectedRowIds }) => {
        return (
            <Row className="flex-between-center">
                <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                    <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Money Accounts</h5>
                </Col>
                <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                    {Object.keys(selectedRowIds).length > 0 ? (
                        <div className="d-flex">
                            <Form.Select size="sm" aria-label="Bulk actions">
                                <option>Bulk Actions</option>
                                <option value="refund">Refund</option>
                                <option value="delete">Delete</option>
                                <option value="archive">Archive</option>
                            </Form.Select>
                            <Button
                                type="button"
                                variant="falcon-default"
                                size="sm"
                                className="ms-2"
                            >
                                Apply
                            </Button>
                        </div>
                    ) : (
                        <div id="orders-actions">
                            <IconButton
                                variant="falcon-primary"
                                size="sm"
                                icon="plus"
                                transform="shrink-3"
                                onClick={() => {
                                    openAddMoneyAccountDialog();
                                }}
                            >
                                <span className="d-none d-sm-inline-block ms-1">New Account</span>

                            </IconButton>
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon="filter"
                                transform="shrink-3"
                                className="mx-2"
                            >
                                <span className="d-none d-sm-inline-block ms-1">Filter</span>
                            </IconButton>
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon="external-link-alt"
                                transform="shrink-3"
                            >
                                <span className="d-none d-sm-inline-block ms-1">Export</span>
                            </IconButton>
                        </div>
                    )}
                </Col>
            </Row>
        );
    };

    return (
        <>

            {
                error && <>
                    <div className="d-block bg-danger p-2 text-white mt-3">{error}</div>
                    <br />
                </>
            }

            <AdvanceTableWrapper
                columns={columns}
                data={moneyAccountData}
                selection
                sortable
                pagination
                perPage={10}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <MoneyAccountsTableHeader table />
                    </Card.Header>
                    <Card.Body className="p-0">

                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination table />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>

            <AddMoneyAccount
                data={addDialogData}
                isDialogOpened={isAddMoneyAccountDialogOpen}
                onCloseDialog={handleCloseAddMoneyAcountDialog}
            />

            <EditMoneyAccount
                data={editDialogData}
                isDialogOpened={isEditMoneyAccountDialogOpen}
                onCloseDialog={handleCloseEditMoneyAccountDialog}
            />

            <DeleteMoneyAccount
                data={deleteDialogData}
                isDialogOpened={isDeleteMoneyAccountDialogOpen}
                onCloseDialog={handleCloseDeleteDialog}
            />
        </>

    );
};

export default MoneyAccounts;
