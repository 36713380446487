import React, { useState, useEffect, forwardRef } from 'react';
import { Card, Dropdown, Col, Form, Row, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import CardDropdown from 'components/common/CardDropdown';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import ExpenseService from 'services/ExpenseService';
import AddExpense from './AddExpense';
import EditExpense from './EditExpense'
import DeleteExpense from './DeleteExpense';
import moment from 'moment';
import MultiDate from 'components/common/MultiDate';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Stat from './Stat';
import DatePicker from "react-datepicker";
import MoneyPlanService from 'services/MoneyPlanService';
import CountUp from 'react-countup';
import EditPaid from './EditPaid';




const MoneyPlan = () => {

    const INITIAL_VALUES = { id: 0, planDate: moment().toDate(), accountId: 0, amount: 0, notes: "", dueDate: moment().toDate() };

    const [ExpenseData, setExpenseData] = useState([]);
    const [error, setError] = useState();
    const [moneyPlans, setMoneyPlans] = useState([]);
    const [totalDuesStat, setTotalDuesStat] = useState({ amount: 0, subAmount: 0 });
    const [totalPaidStat, setTotalPaidStat] = useState({ amount: 0, subAmount: 0 });
    const [totalUnpaidStat, setTotalUnpaidsStat] = useState({ amount: 0, subAmount: 0 });

    const [addDialogData, setAddDialogData] = useState(INITIAL_VALUES);
    const [isAddExpenseDialogOpen, setIsOpenAddExpenseDialog] = useState(false);

    const [editDialogData, setEditDialogData] = useState(INITIAL_VALUES);
    const [isEditExpenseDialogOpen, setIsOpenExpenseDialog] = useState(false);

    const [deleteDialogData, setDeleteDialogData] = useState({ id: 0, categoryName: '', command: '' });
    const [isDeleteExpenseDialogOpen, setIsOpenDeleteExpenseDialog] = useState(false);

    const [paidDialogData, setPaidDialogData] = useState({ id: 0, paidAmount: 0, paidDate: moment().toDate() });
    const [isPaidExpenseDialogOpen, setIsOpenPaidExpenseDialog] = useState(false);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(new Date());
    //const date = new Date();

    const [month, setMonth] = useState(null);

    useEffect(() => {

        //var fromDate = moment(date).subtract(7, 'day').toDate();

        //setStartDate(date);

        var today = new Date();
        setMonth(today);

        console.log("MoneyPlan-useEffect");
        loadData(today);

    }, []);

    function loadData(monthDate) {
        try {

            // console.log('fromDate', fromDate);
            // console.log('toDate', toDate);

            console.log("MoneyPlan-getMoneyPlan");
            MoneyPlanService.getMoneyPlan(monthDate).then(
                response => {

                    if (response.data) {

                        var data = response.data;
                        setMoneyPlans(data.plans);
                        setTotalDuesStat(data.totalDues);
                        setTotalPaidStat(data.paidDues);
                        setTotalUnpaidsStat(data.unpaidDues);

                    }
                },
                error => {
                    setError(error);
                }
            )
        } catch (err) {
            setError(err);
        } finally {
            //setFormLoaded(true);
        }
    }

    function refresh() {
        loadData(month);
    }

    const openAddExpenseDialog = () => {

        const INITIAL_VALUES = { id: 0, planDate: month, accountId: 0, amount: 0, notes: "", dueDate: month };


        setAddDialogData(INITIAL_VALUES)
        setIsOpenAddExpenseDialog(true);
    }

    function handleCloseAddMoneyAcountDialog(isSubmitted) {
        setIsOpenAddExpenseDialog(false);

        if (isSubmitted) {
            refresh();
        }
    }

    const openEditExpenseDialog = (id) => {

        try {

            MoneyPlanService.getMoneyPlanById(id).then(
                response => {
                    //console.log("expense group received " + id);

                    response.data.planDate = moment(response.data.planDate).toDate();
                    response.data.dueDate = moment(response.data.dueDate).toDate();

                    console.log("openEditMoneyPlanDialog", response.data);

                    setEditDialogData(response.data);

                    // if (response.data) {
                    //     setGroups(response.data);
                    // }
                },
                error => {

                }
            )
        } catch (err) {
        } finally {

        }

        setIsOpenExpenseDialog(true);

    }

    function handleCloseEditExpenseDialog(isSubmitted) {
        setIsOpenExpenseDialog(false);

        if (isSubmitted) {
            refresh();
        }
    }

    const openDeleteExpenseDialog = (id, date, category, amount) => {


        setDeleteDialogData({
            id: id,
            date: date,
            category: category,
            amount: amount,
            command: ''
        })

        setIsOpenDeleteExpenseDialog(true);

    }

    function handleCloseDeleteDialog(isSubmitted) {
        setIsOpenDeleteExpenseDialog(false);

        if (isSubmitted) {
            refresh();
        }
    }


    const openPaidExpenseDialog = (id, planAmount) => {


        setPaidDialogData({
            id: id,
            paidDate: moment().toDate(),
            paidAmount: planAmount,
        })

        setIsOpenPaidExpenseDialog(true);

    }

    function handleClosePaidDialog(isSubmitted) {
        setIsOpenPaidExpenseDialog(false);

        if (isSubmitted) {
            refresh();
        }
    }

    function onDateChange(newDate) {
        loadData(newDate);
    }

    function moveNextMonth() {
        var nextDate = moment(month);
        nextDate = nextDate.add(1, 'months')

        setMonth(nextDate.toDate());
        onDateChange(nextDate.toDate());
    }

    function moveToPreviousMonth() {
        var tempDate = moment(month);
        tempDate = tempDate.add(-1, 'months')

        setMonth(tempDate.toDate());
        onDateChange(tempDate.toDate());
    }

    function generateMoneyPlan() {
        try {

            MoneyPlanService.generateMoneyPlan(month).then(
                response => {
                    refresh();
                },
                error => {

                }
            )
        } catch (err) {
        } finally {

        }

    }

    const columns = [
        {
            accessor: 'accountName',
            Header: 'Account',
            Cell: rowData => {
                const { accountName } = rowData.row.original;
                return <h5 className="mb-0 fs--1">{accountName}</h5>
            }
        },
        {
            accessor: 'notes',
            Header: 'Plan Notes',

        },
        {
            accessor: 'amount',
            Header: 'Amount',
            headerProps: { className: 'text-end' },
            cellProps: {
                className: 'text-end fs-0 fw-medium py-2'
            },
            Cell: rowData => `${rowData.row.original.amount}`
        },

        {
            accessor: 'status',
            Header: 'Status',
            headerProps: {
                className: 'text-center',
                style: { maxWidth: '30px' },
            },
            cellProps: {
                className: 'fs-0 text-center',
                style: { maxWidth: '40px', minWidth: '40px' },
            },
            Cell: rowData => {
                const { status } = rowData.row.original;
                return (
                    <SoftBadge
                        pill
                        bg={classNames({
                            success: status === 'paid',
                            primary: status === 'processing',
                            warning: status === 'pending',
                            secondary: status === 'onhold'
                        })}
                        className=""
                    >
                        {status === 'paid' && 'Paid'}
                        {status === 'processing' && 'Processing'}
                        {status === 'pending' && 'Pending'}
                        {status === 'onhold' && 'On-Hold'}
                        <FontAwesomeIcon
                            icon={classNames({
                                check: status === 'paid',
                                redo: status === 'processing',
                                stream: status === 'pending',
                                ban: status === 'onhold'
                            })}
                            transform="shrink-2"
                            className="ms-1"
                        />
                    </SoftBadge>
                );
            }

        },
        {
            accessor: 'dueDate',
            Header: 'Due Date',
            size: 40,
            headerProps: {
                className: 'text-end',
                style: { maxWidth: '60px' },
            },
            cellProps: {
                className: 'text-end',
                style: { maxWidth: '60px' },
            },
            //Cell: rowData => { moment(rowData.row.original.expenseDate).format('dd/MM/yyyy') }
            Cell: rowData => {
                const { dueDate } = rowData.row.original;
                const formatedDate = moment(dueDate).format('DD/MM/yyyy')
                return <>{formatedDate} </>
            }
        },
        {
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            size: 10,
            cellProps: {
                className: 'text-end'
            },
            Cell: (rowData) => {
                const { id, expenseDate, expenseCategoryName, amount, status } = rowData.row.original;

                return (
                    <CardDropdown iconClassName="fs--1" >

                        <div className="py-2" >
                            <Dropdown.Item onClick={() => {
                                openEditExpenseDialog(id);
                            }}>Edit</Dropdown.Item>

                            {status == "pending" && <Dropdown.Item onClick={() => {
                                openPaidExpenseDialog(id, amount);
                            }} className="text-success">Paid</Dropdown.Item>
                            }

                            <Dropdown.Divider as="div" />
                            <Dropdown.Item onClick={() => {
                                openDeleteExpenseDialog(id, expenseDate, expenseCategoryName, amount);
                            }} className="text-danger">Delete</Dropdown.Item>
                        </div>
                    </CardDropdown>
                );
            }
        }
    ];


    const ExpensesTableHeader = ({
        selectedRowIds,
        globalFilter,
        setGlobalFilter,
    }) => {
        return (
            <Row className="flex-between-center">

                {/* <Col xs="auto" >
                    <AdvanceTableSearchBox
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                        placeholder="Search by any"
                    />
                </Col> */}

                <Col xs="auto">
                    <Row className="g-sm-4">
                        {/* {candleChartStatsData.map((stat, index) => (
                            <Stat
                                key={stat.id}
                                statInfo={stat}
                                isLast={index === candleChartStatsData.length - 1}
                            />
                        ))} */}

                        <Stat
                            key={1}
                            title={"Dues"}
                            statInfo={totalDuesStat}
                            isLast={false}
                            showStat={false}
                        />
                        <Stat
                            key={2}
                            title={"Paid"}
                            statInfo={totalPaidStat}
                            isLast={false}
                            showStat={false}
                        />
                        <Stat
                            key={3}
                            title={"Unpaid"}
                            statInfo={totalUnpaidStat}
                            isLast={true}
                            showStat={false}
                        />
                    </Row>
                </Col>
                <Col xs={8} sm="auto" className="ms-auto text-end ps-0">


                    <div id="orders-actions">

                        <IconButton
                            variant="falcon-primary"
                            size="sm"
                            icon="plus"
                            transform="shrink-3"
                            onClick={() => {
                                openAddExpenseDialog();
                            }}
                        >
                            <span className="d-none d-sm-inline-block ms-1">New Plan</span>

                        </IconButton>
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="filter"
                            transform="shrink-3"
                            className="mx-2"
                        >
                            <span className="d-none d-sm-inline-block ms-1">Filter</span>
                        </IconButton>
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="external-link-alt"
                            transform="shrink-3"
                        >
                            <span className="d-none d-sm-inline-block ms-1">Export</span>
                        </IconButton>
                    </div>

                </Col>
            </Row>
        );
    };

    return (
        <>

            {
                error && <>
                    <div className="d-block bg-danger p-2 text-white mt-3">{error}</div>
                    <br />
                </>
            }

            <Card className="mb-3 " >
                <Card.Body>
                    <Row className="justify-content-between align-items-center">
                        <Col md>
                            <h5 className="mb-2 mb-md-0">My Money Plan</h5>
                        </Col>
                        <Col xs="auto">

                        </Col>
                        <Col xs="auto">
                            <h6 className="text-700 mb-0 text-nowrap">Showing Data For:</h6>
                        </Col>
                        <Col xs="auto">
                            <div className="p-x1">
                                <Flex justifyContent="between">

                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip style={{ position: 'fixed' }} id="nextTooltip">
                                                Previous
                                            </Tooltip>
                                        }
                                    >
                                        <Button
                                            variant="falcon-default"
                                            size="sm"
                                            className="me-1"
                                            onClick={() => {
                                                moveToPreviousMonth();
                                            }}
                                        >
                                            <FontAwesomeIcon icon="chevron-left" />
                                        </Button>
                                    </OverlayTrigger>
                                    <DatePicker
                                        className='px-sm-4'
                                        selected={month}
                                        onChange={(date) => {
                                            setMonth(date);
                                            onDateChange(date);
                                        }}
                                        dateFormat="MMM yyyy"
                                        customInput={<DateInput />}
                                        showMonthYearPicker
                                    />

                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip style={{ position: 'fixed' }} id="nextTooltip">
                                                Next
                                            </Tooltip>
                                        }
                                    >
                                        <Button
                                            variant="falcon-default"
                                            size="sm"
                                            className="ms-1"
                                            onClick={() => {
                                                moveNextMonth();
                                            }}
                                        >
                                            <FontAwesomeIcon icon="chevron-right" />
                                        </Button>
                                    </OverlayTrigger>
                                </Flex>
                            </div>
                        </Col>


                        {moneyPlans.length == 0 && <Col xs="auto">
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon="external-link-alt"
                                transform="shrink-3"
                                onClick={() => {
                                    generateMoneyPlan();
                                }}
                            >
                                <span className="d-none d-sm-inline-block ms-1">Generate</span>
                            </IconButton>
                        </Col>
                        }
                    </Row>
                </Card.Body>
            </Card>

            <AdvanceTableWrapper
                columns={columns}
                data={moneyPlans}
                sortable
                pagination
                perPage={10}
            >
                <Card className="mb-3 h-100">
                    <Card.Header>
                        <ExpensesTableHeader table />
                    </Card.Header>
                    <Card.Body className="p-0">

                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination table />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>

            <AddExpense
                data={addDialogData}
                isDialogOpened={isAddExpenseDialogOpen}
                onCloseDialog={handleCloseAddMoneyAcountDialog}
            />

            <EditExpense
                data={editDialogData}
                isDialogOpened={isEditExpenseDialogOpen}
                onCloseDialog={handleCloseEditExpenseDialog}
            />

            <DeleteExpense
                data={deleteDialogData}
                isDialogOpened={isDeleteExpenseDialogOpen}
                onCloseDialog={handleCloseDeleteDialog}
            />

            <EditPaid
                data={paidDialogData}
                isDialogOpened={isPaidExpenseDialogOpen}
                onCloseDialog={handleClosePaidDialog}
            />
        </>

    );
};

export default MoneyPlan;

const DateInput = forwardRef(({ value, onClick }, ref) => (
    <div className="position-relative">
        <Form.Control
            size="sm"
            ref={ref}
            onClick={onClick}
            value={value}
            className="ps-4"
            onChange={e => {
                console.log({ e });
            }}
        />
        <FontAwesomeIcon
            icon="calendar-alt"
            className="text-primary position-absolute top-50 translate-middle-y ms-2"
        />
    </div>
));


const TestMoneyPlan = [
    {
        id: 1,
        accountName: "HDFC Loan EMI",
        amount: 17500,
        dueDate: "05/10/2023",
        status: "pending",

    },
    {
        id: 2,
        accountName: "AXIS Loan EMI",
        amount: 25500,
        dueDate: "06/10/2023",
        status: "paid",
    }
]

export const candleChartStatsData = [
    {
        id: 1,
        title: 'Dues',
        showStat: false,
        grow: {
            isGrow: true,
            growAmount: 20.2,
            color: 'success'
        },
        amount: '₹21,268'
    },
    {
        id: 2,
        title: 'Paid',
        grow: {
            isGrow: false,
            growAmount: 20,
            color: 'success'
        },
        amount: '₹21,000'
    },
    {
        id: 3,
        title: 'Unpaid',
        grow: {
            isGrow: true,
            growAmount: 18,
            color: 'primary'
        },
        amount: '₹268'
    }
];