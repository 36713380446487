import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Form } from 'react-bootstrap';
import AppContext from 'context/Context';
import { Formik, Field, Form as FormikForm } from 'formik';
import { FormTextField, FormTextAreaField, FormikSelect4 } from 'helpers/bootstrap-formlk';
import MoneyAccountService from 'services/MoneyAccountService';

//https://codesandbox.io/s/react-bootstrap-formik-pb831?file=/src/form-field.js


const EditMoneyAccount = ({
    data,
    isDialogOpened,
    onCloseDialog
}) => {
    const {
        config: { isDark }
    } = useContext(AppContext);

    const AccountCategories = [
        { value: 'CASH', label: ' Cash' },
        { value: 'BANK', label: 'Bank' },
        { value: 'CCARD', label: 'Credit Card' },
    ];


    const INITIAL_VALUES = { id: 0, accountName: '', category: '', details: '' };
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState(null);
    const [expenseGroup, setExpenseGroup] = useState([]);

    function defaultState() {
        setError('');
    }


    useEffect(() => {
        defaultState();

        if (data) {

            //console.log(data);
            setFormData(data);

        }

    }, [data]);


    function handleClose(isSubmitted) {
        onCloseDialog(isSubmitted);
    }



    function checkDefaultValue() {

    }

    const submit = async (values) => {

        try {
            setIsLoading(true);

            MoneyAccountService.updateMoneyAccount(values.id, values.accountName, values.category, values.details).then(
                response => {
                    handleClose(true);
                },
                error => {
                    setError(error);
                }
            )
        } catch (err) {
            setError(err);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal
            show={isDialogOpened}
            onHide={e => handleClose(false)}
            contentClassName="border"
            backdrop="static"
            centered
        >
            <Formik
                initialValues={formData}
                enableReinitialize={true}
                onSubmit={submit}>

                {({ values, setFieldValue }) => (

                    <FormikForm >
                        <Modal.Header
                            closeButton
                            closeVariant={isDark ? 'white' : undefined}
                            className="bg-light px-x1 border-bottom-0"
                        >
                            <Modal.Title as="h5"> New Money Account</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="p-x1">

                            {
                                error && <>
                                    <div className="d-block bg-danger p-2 text-white mt-3">{error}</div>
                                    <br />
                                </>
                            }

                            <Form.Group className="mb-3" controlId="formCategoryName">
                                <Form.Label className="fs-0">Account Name</Form.Label>
                                <FormTextField name="accountName" type="text" />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formExpenseGroupId">
                                <Form.Label className="fs-0">Expense Group</Form.Label>
                                <Field name='category' component={FormikSelect4}
                                    options={AccountCategories} labelField='label' valueField='value' />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formDescription">
                                <Form.Label className="fs-0">Details</Form.Label>
                                <FormTextAreaField name="details" rows={3} />
                            </Form.Group>

                        </Modal.Body>
                        <Modal.Footer className="bg-light px-x1 border-top-0">

                            <Button
                                variant="default"
                                type="button"
                                onClick={e => handleClose(false)}
                                className="px-4 mx-0"
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                type="submit"
                                //onClick={handleClose}
                                className="px-4 mx-0 ms-2"
                            >
                                Save
                            </Button>
                        </Modal.Footer>
                    </FormikForm>
                )}
            </Formik>
        </Modal>
    );
};

EditMoneyAccount.propTypes = {
    data: PropTypes.object.isRequired,
    isDialogOpened: PropTypes.bool.isRequired,
    onCloseDialog: PropTypes.func.isRequired
};

export default EditMoneyAccount;
