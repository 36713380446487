import React, { useState, useEffect, useRef } from 'react';
import { Card, Dropdown, Col, Form, Row, Button } from 'react-bootstrap';
import moment from 'moment';
import { DataManager, UrlAdaptor, ODataAdaptor } from '@syncfusion/ej2-data';
import { GridComponent, VirtualScroll, ColumnsDirective, ColumnDirective, Page, Edit, Toolbar, Group, Sort, Inject, Filter } from '@syncfusion/ej2-react-grids';
import { Aggregate, AggregateColumnDirective, AggregateColumnsDirective, AggregateDirective, AggregatesDirective } from '@syncfusion/ej2-react-grids';
import { DataUtil, Query, WebApiAdaptor, WebMethodAdaptor } from '@syncfusion/ej2-data';
import { Internationalization, compile } from '@syncfusion/ej2-base';
import { DialogFormTemplate } from './AddNew';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import { parseDate } from 'helpers/utils';
import { apiURL } from 'services/api';

const TransactionsSync = () => {

    let grid;
    const [error, setError] = useState();

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const lastDate = moment(new Date()).subtract(30, 'day').toDate();
    const [newDateRange, setDateRange] = useState([lastDate, new Date()]);

    const date = new Date();

    const maxDate = new Date();

    useEffect(() => {

        var fromDate = moment(date).subtract(30, 'day').toDate();

        var fromDateStr = parseDate(fromDate);
        setStartDate(fromDateStr);
        var endDateStr = parseDate(new Date());
        setEndDate(endDateStr);

        //DataUtil.serverTimezoneOffset = 0;

    }, []);


    const formatDate = (date) => {

        console.log('date', date);

        const day = date.getDate().toString().padStart(2, '0');
        const month = date.toLocaleString('en-US', { month: 'short' });
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };


    function gridTemplate({ items }) {

        if (items.length > 0) {
            var item = items[0].expenseDate;
            var expenseDate = formatDate(item);
            return <span>{expenseDate}</span>;
        }
        return '';
    }

    const groupOptions = {
        showDropArea: false, howGroupedColumn: false, columns: ['expenseDate'], captionTemplate: gridTemplate
    }

    const dialogTemplate = (props) => {
        return (<DialogFormTemplate {...props} height='400px' width='400px' />);
    };

    const toolbarOptions = ['Add', 'Edit', 'Delete'];
    const editparams = { params: { popupHeight: '100px' } };
    const editSettings = { allowEditing: true, editparams: editparams, allowAdding: true, allowDeleting: true, showDeleteConfirmDialog: true, mode: 'Dialog', template: dialogTemplate };
    const pageSettings = { pageSize: 50 };
    const query = new Query().addParams('Syncfusion_React_Grid', 'true');


    const dataManager = new DataManager({
        adaptor: new UrlAdaptor(),
        crossDomain: true,
        insertUrl: apiURL + "/Expense/create",
        removeUrl: apiURL + "/Expense/delete",
        updateUrl: apiURL + "/Expense/update",
        url: apiURL + "/Expense/GetAllExpenses?startDate=" + startDate + "&endDate=" + endDate
    });

    const captionTemplate = (props) => {
        return (<span>{props.Sum}</span>)
    }

    const actionBegin = (args) => {

        if ((args.requestType === 'beginEdit' || args.requestType === 'add')) {

        }

        if (args.requestType === 'save') {
            // cast string to integer value.
            //(args.data as ColumnDataType).Freight = parseFloat(((args as any).form.querySelector('#Freight').ej2_instances[0] as HTMLInputElement).value);

            args.data.expenseDate = parseDate(args.data.expenseDate);
            console.log('on save', args);
        }
    }

    const toolbarClick = (args) => {
        if (args.item.properties.text === 'Delete') {
            const dialogObj = grid.editModule.dialogObj;
            dialogObj.header = 'Delete Confirmation Dialog';
            dialogObj.showCloseIcon = true;
        }
    }

    const dateChanged = (args) => {

        var fromDate = parseDate(args.startDate);
        var endDate = parseDate(args.endDate);

        setStartDate(fromDate);
        setEndDate(endDate);

        // grid.refresh();
    }


    return (

        <>
            {
                error && <>
                    <div className="d-block bg-danger p-2 text-white mt-3">{error}</div>
                    <br />
                </>
            }

            <Card className="mb-3">
                <Card.Body>
                    <Row className="justify-content-between align-items-center">
                        <Col md>
                            <h5 className="mb-2 mb-md-0">My Expenses</h5>
                        </Col>
                        <Col xs="auto">
                        </Col>
                        <Col xs="auto">
                            <h6 className="text-700 mb-0 text-nowrap">Showing Data For:</h6>
                        </Col>
                        <Col xs={3}>
                            {/* <MultiDate
                                initStartDate={startDate}
                                initEndDate={endDate}
                                onDateChange={onDateChange} /> */}

                            <DateRangePickerComponent id="daterangepicker" format="dd-MMM-yyyy" placeholder='Select a range' change={dateChanged} max={maxDate} value={newDateRange} allowEdit={false} />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            {/* <GridComponent dataSource={dataManager} dataSourceChanged={dataSourceChanged} dataStateChange={dataStateChange} toolbar={toolbarOptions} height={570} pageSettings={pageSettings} editSettings={editSettings} allowGrouping={true} groupSettings={groupOptions} rowHeight={30}></GridComponent> */}
            <GridComponent ref={g => grid = g} actionBegin={actionBegin} query={query} dataSource={dataManager} toolbar={toolbarOptions} toolbarClick={toolbarClick} height={570} pageSettings={pageSettings} editSettings={editSettings} allowGrouping={true} groupSettings={groupOptions} rowHeight={30}>
                <ColumnsDirective>
                    <ColumnDirective field='id' isPrimaryKey={true} visible={false} width='100' />
                    <ColumnDirective field='expenseDate' headerText='Expense Date' type='date' format='dd-MMM-yyyy' width='100' textAlign="Right" />
                    <ColumnDirective field='expenseCategoryName' headerText='Category Name' width='100' />
                    <ColumnDirective field='expenseCategoryId' visible={false} headerText='Category Name' width='100' />
                    <ColumnDirective field='moneyAccountId' visible={false} headerText='Account Name' width='100' />
                    <ColumnDirective field='description' width='100' headerText='Description' textAlign="Left" />
                    <ColumnDirective field='amount' width='100' headerText='Amount' format="N2" textAlign="Right" />
                </ColumnsDirective>
                <AggregatesDirective>
                    <AggregateDirective>
                        <AggregateColumnsDirective>
                            <AggregateColumnDirective field='amount' type='Sum' format="N2" groupCaptionTemplate={captionTemplate} />
                        </AggregateColumnsDirective>
                    </AggregateDirective>
                </AggregatesDirective>
                <Inject services={[Aggregate, Page, VirtualScroll, Group, Sort, Edit, Toolbar, Filter]} />

            </GridComponent>
        </>
    );


};
export default TransactionsSync;
