import Divider from 'components/common/Divider';
import { error } from 'is_js';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Col, Form, Row, Alert } from 'react-bootstrap';
import { propTypes } from 'react-bootstrap/esm/Image';
import { Link, useNavigate } from 'react-router-dom';
import AuthService from 'services/authService';

//import { toast } from 'react-toastify';


const LoginForm = ({ setLoggedIn, hasLabel }) => {

    let navigate = useNavigate();
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const [formData, setFormData] = useState({
        email: '',
        password: '',
        remember: false
    });

    const handleSubmit = e => {
        e.preventDefault();
        console.log("Login " + formData.email);

        try {
            setIsLoading(true);

            AuthService.login(formData.email, formData.password).then(
                response => {
                    console.log("Login successful " + response.accessToken);
                    window.location.replace("/default");
                    //navigate('/default');
                },
                error => {
                    console.log("found err " + error);
                    setError(error);
                }
            )
        } catch (err) {
            setError(err);
        } finally {
            setIsLoading(false);
        }





        // localStorage.setItem("password", formData.password);
        // window.location.replace("/default");


        //setLoggedIn(true);

        // navigate('/default');
        // window.location.reload();

    }

    const handleFieldChasnge = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <Form onSubmit={handleSubmit}>

            {
                error && <Alert variant="danger" onClose={() => setError("")} dismissible className='mt-3'>
                    <Alert.Heading>Something Wrong!</Alert.Heading>
                    <p>
                        {error}
                    </p>
                </Alert>
            }

            <Form.Group className='mb-3'>
                {hasLabel && <Form.Label>Email address</Form.Label>}
                <Form.Control
                    placeholder={!hasLabel ? 'Email address' : ''}
                    value={formData.email}
                    name='email'
                    onChange={handleFieldChasnge}
                    type='email'
                />
            </Form.Group>
            <Form.Group className='mb-3'>
                {hasLabel && <Form.Label>Password</Form.Label>}
                <Form.Control
                    placeholder={!hasLabel ? 'Password' : ''}
                    value={formData.password}
                    name='password'
                    onChange={handleFieldChasnge}
                    type='password'
                />
            </Form.Group>
            <Row className="justify-content-between align-items-center">
                <Col xs="auto">
                    <Form.Check type="checkbox" id="rememberMe" className="mb-0">
                        <Form.Check.Input
                            type="checkbox"
                            name="remember"
                            checked={formData.remember}
                            onChange={e =>
                                setFormData({
                                    ...formData,
                                    remember: e.target.checked
                                })
                            }
                        />
                        <Form.Check.Label className="mb-0 text-700">
                            Remember me
                        </Form.Check.Label>
                    </Form.Check>
                </Col>

                <Col xs="auto">
                    <Link
                        className="fs--1 mb-0"
                        to={`/authentication/forgot-password`}
                    >
                        Forgot Password?
                    </Link>
                </Col>
            </Row>
            <Form.Group>
                <Button
                    type='submit'
                    color='primary'
                    className='mt-3 w-100'
                    disabled={!formData.email || !formData.password}
                >
                    Log in
                </Button>
            </Form.Group>




        </Form>
    )


};

LoginForm.prototype = {
    setLoggedIn: PropTypes.func,
    hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
    hasLabel: false
};

export default LoginForm