import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Form } from 'react-bootstrap';
import AppContext from 'context/Context';
import { Formik, Field, Form as FormikForm } from 'formik';
import { FormTextField, FormTextAreaField, FormikSelect4, FormikDate, CustomDateInput } from 'helpers/bootstrap-formlk';
import ExpenseService from 'services/ExpenseService';
import UserService from 'services/userService';
import MoneyAccountService from 'services/MoneyAccountService';
import DatePicker from "react-datepicker";
import moment from 'moment';
import * as Yup from 'yup';
import MoneyPlanService from 'services/MoneyPlanService';

//https://codesandbox.io/s/react-bootstrap-formik-pb831?file=/src/form-field.js


const AddExpense = ({
    data,
    isDialogOpened,
    onCloseDialog
}) => {
    const {
        config: { isDark }
    } = useContext(AppContext);

    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState(null);
    const [expenseCategories, setExpenseCategories] = useState([]);
    const [moneyAccounts, setMoneyAccounts] = useState([]);

    function defaultState() {
        setError('');
    }


    useEffect(() => {
        defaultState();

        if (data) {

            loadExpenseCategories();
            loadMoneyAccounts();

            //console.log(data);
            setFormData(data);

        }

    }, [data]);


    function loadExpenseCategories() {
        try {

            UserService.getExpenseCategories().then(
                response => {
                    if (response.data) {
                        setExpenseCategories(response.data);
                    }
                },
                error => {

                }
            )
        } catch (err) {

        } finally {
        }
    }

    function loadMoneyAccounts() {
        try {

            MoneyAccountService.getMoneyAccounts().then(
                response => {
                    if (response.data) {
                        setMoneyAccounts(response.data);
                    }
                },
                error => {

                }
            )
        } catch (err) {

        } finally {
        }
    }

    function handleClose(isSubmitted) {
        onCloseDialog(isSubmitted);
    }



    function checkDefaultValue() {

    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const submit = async (values) => {

        try {
            setIsLoading(true);
            console.log("createMoneyPlan", values);

            //var expenseDate = moment.utc(values.expenseDate, "DD/MM/YYYY").toDate()
            const planDateStr = formatDate(values.planDate);
            const dueDateStr = formatDate(values.dueDate);

            MoneyPlanService.createMoneyPlan(planDateStr, values.accountId, values.amount, dueDateStr, values.notes).then(
                response => {
                    handleClose(true);
                },
                error => {
                    setError(error);
                }
            )
        } catch (err) {
            setError(err);
        } finally {
            setIsLoading(false);
        }
    };
    const [date, setDate] = useState(null);

    var minDate = moment().subtract(1, "days").toDate();
    var maxDate = moment().toDate();

    const AddExpenseSchema = Yup.object().shape({
        amount: Yup.number()
            .min(1, 'Enter valid amount')
            .required('Amount is required'),
        accountId: Yup.number()
            .min(1, 'required'),
        planDate: Yup.date(),
        // .min(minDate, 'min required'),
        dueDate: Yup.date()

    });

    return (
        <Modal
            show={isDialogOpened}
            onHide={e => handleClose(false)}
            contentClassName="border"
            backdrop="static"
            centered
        >
            <Formik
                initialValues={formData}
                enableReinitialize={true}
                validationSchema={AddExpenseSchema}
                onSubmit={submit}>

                {({ errors, touched, values, setFieldValue }) => (

                    <FormikForm >
                        <Modal.Header
                            closeButton
                            closeVariant={isDark ? 'white' : undefined}
                            className="bg-light px-x1 border-bottom-0"
                        >
                            <Modal.Title as="h5"> New Plan</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="p-x1">

                            {
                                error && <>
                                    <div className="d-block bg-danger p-2 text-white mt-3">{error}</div>
                                    <br />
                                </>
                            }

                            <Form.Group className="mb-3" controlId="formExpenseDate">
                                <Form.Label className="fs-0">Plan Month</Form.Label>
                                <DatePicker
                                    selected={values.planDate}
                                    readOnly={true}
                                    onChange={date => {
                                        setFieldValue('planDate', date);
                                    }}
                                    className="form-control"
                                    placeholderText="DD-MM-YYYY"
                                    dateFormat="MMM yyyy"
                                />
                                <Form.Text className="text-danger">

                                    {errors.planDate && touched.planDate ? (
                                        <div> {errors.planDate} </div>
                                    ) : null}
                                </Form.Text>

                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formCategoryName">
                                <Form.Label className="fs-0">Amount</Form.Label>
                                <FormTextField name="amount" type="text" />
                                <Form.Text className="text-danger">
                                    {errors.amount && touched.amount ? (
                                        <div> {errors.amount} </div>
                                    ) : null}
                                </Form.Text>

                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formExpenseGroupId">
                                <Form.Label className="fs-0">Account</Form.Label>
                                <Field name='accountId' component={FormikSelect4}
                                    options={expenseCategories} labelField='categoryName' valueField='id' />
                                <Form.Text className="text-danger">
                                    {errors.expenseCategoryId && touched.expenseCategoryId ? (
                                        <div> {errors.expenseCategoryId} </div>
                                    ) : null}
                                </Form.Text>

                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formDescription">
                                <Form.Label className="fs-0">Details</Form.Label>
                                <FormTextAreaField name="notes" rows={3} />

                            </Form.Group>


                            <Form.Group className="mb-3" controlId="formExpenseDate">
                                <Form.Label className="fs-0">Due Date</Form.Label>
                                <DatePicker
                                    selected={values.dueDate}
                                    onChange={date => {
                                        setFieldValue('dueDate', date);
                                    }}
                                    className="form-control"
                                    placeholderText="DD-MM-YYYY"
                                    dateFormat="dd-MM-yyyy"
                                />
                                <Form.Text className="text-danger">

                                    {errors.dueDate && touched.dueDate ? (
                                        <div> {errors.dueDate} </div>
                                    ) : null}
                                </Form.Text>

                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer className="bg-light px-x1 border-top-0">

                            <Button
                                variant="default"
                                type="button"
                                onClick={e => handleClose(false)}
                                className="px-4 mx-0"
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                type="submit"
                                //onClick={handleClose}
                                className="px-4 mx-0 ms-2"
                            >
                                Save
                            </Button>
                        </Modal.Footer>
                    </FormikForm>
                )}
            </Formik>
        </Modal >
    );
};

AddExpense.propTypes = {
    data: PropTypes.object.isRequired,
    isDialogOpened: PropTypes.bool.isRequired,
    onCloseDialog: PropTypes.func.isRequired
};

export default AddExpense;
