import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import bg1 from 'assets/img/generic/bg-1.jpg';
import dashboardDark from 'assets/img/generic/dashboard-alt-dark.png';
import dashboard from 'assets/img/generic/dashboard-alt.png';
import Section from 'components/common/Section';
import AppContext from 'context/Context';
import React, { useContext } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Typewriter } from 'react-simple-typewriter';

const Hero = () => {
  // const {
  //   config: { isDark, isRTL }
  // } = useContext(AppContext);

  const isDark = false;


  return (
    <Section
      className="py-0 overflow-hidden light"
      // image={bg1}
      position="center bottom"
      overlay
    >
      <Row className="justify-content-center align-items-center pt-8 pt-lg-10 pb-lg-9 pb-xl-0">
        <Col
          md={12}
          lg={12}
          xl={12}
          className="pb-7 pb-xl-9 text-center"
        >

          <h1 className=" fw-light">
            Boost Productivity with <strong>Spenzzy<span
              className="text-warning">.</span></strong>
          </h1>
          <p className="lead opacity-75">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          </p>
          {/* <Button
            as={Link}
            variant="outline-primary"
            size="lg"
            className="border-2 rounded-pill mt-4 fs-0 py-2"
            to="login"
          >
            Try it for free
            <FontAwesomeIcon icon="play" transform="shrink-6 down-1 right-5" />
          </Button> */}
          <Link to={"login"}>
            <Button variant="warning" className="rounded-pill mt-4 fs-0 py-2" size="lg">
              Try it for free
              <FontAwesomeIcon icon="play" transform="shrink-6 down-1 right-5" />
            </Button>
          </Link>


        </Col>

      </Row>
    </Section>
  );
};

export default Hero;
